import states from './states';

window.app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider',
    function($stateProvider, $urlRouterProvider, $locationProvider) {
        // Populate states
        for (let prop in states) {
            $stateProvider.state(prop, states[prop]);
        }

        // Default route
        $urlRouterProvider.otherwise('/');

        // Set 'hash-bang'
        $locationProvider.html5Mode(true)
    }]);
