import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';

window.app.service('CategoryServ', ['$q', '$http',
    function($q, $http) {
        let categories = [];

        fetchCategories();

        this.getCategories = (withSubcategories, parentCategory) => {
            const deferred = $q.defer();

            if (categories.length) {
                deferred.resolve(filterCategories(categories, withSubcategories, parentCategory));
            } else {
                fetchCategories(function() {
                    deferred.resolve(filterCategories(categories, withSubcategories, parentCategory));
                })
            }

            return deferred.promise;
        };

        this.getCategory = categorySlug => {
            const deferred = $q.defer();

            $http.get('/api/v1/eshop/product/category/'+categorySlug, null, {cache: true})
                .then(function(data) {
                    deferred.resolve(data.data);
                });

            return deferred.promise;
        };

        function filterCategories(cats, subC, parent) {
            if (subC === false) {
                cats = cats.filter(n => !n.parent);
            }
            if (parent) {
                cats = cats.filter(n => {
                    if (n.parent) {
                        return n.parent.slug === parent;
                    } else {
                        return false;
                    }
                })
            }
            return cats;
        }

        function fetchCategories(cb) {
            $http.get('/api/v1/eshop/product/categories', null, {cache: true})
                .then(function(data) {
                    categories = data.data;
                    if (cb) {
                        cb();
                    }
                });
        }
    }]);
