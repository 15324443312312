/**
 * @version  3.1-SPA
 */
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import ACconfig from './config';

/**
 * Banners or
 * @param  {Array} promotions objects containing promo id and name
 */
export function viewPromotion(promotions) {
    if (window.gtag) {
        window.gtag('event', 'view_promotion', {
            promotions: promotions,
        });
    }
}

export function selectPromotion(promotions) {
    if (window.gtag) {
        window.gtag('event', 'select_content', {
            promotions: promotions,
        });
    }
}

export function selectProduct(products) {
    if (window.gtag) {
        window.gtag('event', 'select_content', {
            content_type: 'product',
            items: prepareForGoogle(products),
        });
    }
}

export function productView(products) {
    if (window.gtag) {
        window.gtag('event', 'view_item', {
            items: prepareForGoogle(products),
            ecomm_prodid: _map(products, 'id'),
            ecomm_totalvalue: Number(products[0].price),
            ecomm_pagetype: 'product',
            ecomm_category: products[0].category ? products[0].category.name : 'unknown',
            isSaleItem: !!products[0].originalPrice,
        });
    }
    if (window.fbq) {
        window.fbq('track', 'ViewContent', {
            content_type: 'product',
            content_ids: products[0] ? products[0].id : "",
            content_name: products[0] ? products[0].name : "",
        });
    }
}

/**
 * Addtocart tracking
 * @param {Array}  products
 * @param {String} source   from which type of page the product was added -  Can be 'product', 'cart', 'home', 'searchresults' or 'category'
 */
 export function addToCart(products, source) {
    let addVal = _reduce(products, function(sum, n) { sum = sum + (n.amount * n.price); return sum; });
    if (window.gtag) {
        window.gtag('event', 'add_to_cart', {
            currency: 'CZK',
            value: addVal,
            items: prepareForGoogle(products),
            ecomm_prodid: _map(products, 'id'),
            ecomm_totalvalue: addVal,
            ecomm_pagetype: source,
            ecomm_category: products[0].category ? products[0].category.name : 'unknown',
        });
    }
    if (window.fbq) {
        let product = products[0];
        window.fbq('track', 'AddToCart', {
            content_type: 'product',
            content_ids: [product.slug],
            content_name: product.name,
            value: product.price
        });
    }
}

export function removeFromCart(products) {
    if (window.gtag) {
        window.gtag('event', 'remove_from_cart', {
            items: prepareForGoogle(products),
        });
    }
}

// 1st step of checkout - CAR
export function beginCheckout(products, coupon) {
    if (window.gtag) {
        window.gtag('event', 'begin_checkout', {
            items: prepareForGoogle(products),
            coupon: coupon,
        });
    }
    if (window.fbq) window.fbq('track', 'InitiateCheckout');
}

// Other steps of checkou
export function continueCheckout(products, step, coupon) {
    if (window.gtag) {
        window.gtag('event', 'checkout_progress', {
            items: prepareForGoogle(products),
            coupon: coupon,
            checkout_step: step,
        });
    }
}

/**
 * Setting the shipping and payment options. Value is the selected method, not money value
 * @param  {Number} step
 * @param  {String} option 'shipping method' or 'payment method'
 * @param  {String} value  name of the method
 */
export function checkoutOption(step, option, value) {
    if (window.gtag) {
        window.gtag('event', 'set_checkout_option', {
            checkout_step: step,
            checkout_option: option,
            value: value,
        });
    }
}

// Completing billing informatio
export function paymentInfo() {
    if (window.gtag) {
        window.gtag('event', 'add_payment_info');
    }
}

/**
 * Tracks order completion
 * @param  {String} orderId
 * @param  {Array}  products
 * @param  {Object} data     contains cartPrice, vat and shippingPrice
 */
export function purchase(orderId, products, data) {
    if (window.gtag) {
        gtagOrder(orderId, products, data);
    } else {
        $(document).on("window.gtag:loaded", function() {
            gtagOrder(orderId, products, data);
        });
    }
    if (window.fbq) {
        fbOrder(products, data);
    } else {
        $(document).on("fbPixel:loaded", function() {
            fbOrder(products, data);
        });
    }
}

export function search(query) {
    if (window.gtag) {
        window.gtag('event', 'search', { search_term: String(query) });
    }
}

export function productListView(products, listName) {
    if (window.gtag) {
        window.gtag('event', 'view_item_list', {
            items: prepareForGoogle(products, listName),
        });
    }
}

export function exception(description, isFatal) {
    if (window.gtag) {
        window.gtag('event', 'exception', {
            description: description,
            fatal: isFatal
        });
    }
}

export function event(name, data) {
    if (window.gtag) {
        let evObj = {
            'event_category': data.category,
            'event_label': data.label,
            'value': data.value, // Number
        };
        evObj = _omitBy(evObj, _isUndefined);
        window.gtag('event', name, evObj);
    }
}

function prepareForGoogle(products, listName) {
    let gProds = [];
    _forEach(products, function(n, i) {
        gProds.push({
            "id": n.slug,
            "name": n.name,
            "list_name": listName ? listName : (n.acquisition ? n.acquisition : "Detail produktu"),
            "brand": n.brand ? n.brand.name : "",
            "category": n.category ? n.category.name : "",
            "variant": n.variantName,
            "list_position": i,
            "quantity": n.amount || 1,
            "price": n.price ? Number(n.price) : 0,
            "vat": n.vat ? Number(n.vat) : 0,
        });
    });
    return gProds;
}

export function gtagOrder(orderId, products, data) {
    window.gtag('event', 'purchase', {
        transaction_id: String(orderId),
        affiliation: "", // NOT YET
        currency: "CZK",
        value: Number(data.cartPrice),
        tax: Number(data.vat),
        shipping: Number(data.shippingPrice),
        items: prepareForGoogle(products),
    });
    if (ACconfig.adwords && ACconfig.adwordsConvId) {
        window.gtag('event', 'conversion', {
            'send_to': ACconfig.adwords + '/' + ACconfig.adwordsConvId,
            'value': Number(data.cartPrice),
            'currency': 'CZK',
            'transaction_id': orderId,
        });
    }
}

export function fbOrder(products, data) {
    window.fbq('track', 'Purchase', {
        content_type: 'product',
        content_ids: _map(products, 'id'),
        value: data.cartPrice,
        currency: "CZK"
    });
}
