import * as tracker from '../utils/commerce';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _last from 'lodash/last';
import _reverse from 'lodash/reverse';
import $ from 'jquery';
import * as gallery from '../utils/gallery';

import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

require('../_base/cross-sell-serv');
require('../_base/growl-serv');
require('../_base/splash-serv');
require('../_base/cartpopup-serv');

window.app.controller('ProductDetailCtrl',
['$scope', 'PageMetaServ', '$http', '$sce', '$timeout', 'GrowlServ', 'CartPopupServ', 'SplashServ', 'CrossSellServ', '$q', 'CartServ',
function($scope, meta, $http, $sce, $timeout, growl, cartPopup, splash, crossSellServ, $q, cart) {
    let products = [], pickedVariants = [], variantSelects = [];
    let productDetailUrl = "/api/v1/eshop/product/product/";
    let productSlug = $scope.st.params.product;
    $scope.review = {};

    $scope.requestProductForm = {};

    if (productSlug) {
        loadProduct(productDetailUrl + productSlug, function(product) {
            getCrossSell(product.slug, product.category.slug);

            $scope.product = product;
            $scope.productLoaded = true;

            $timeout(function() {
                gallery.createGalleries();
            });

            getReviews(product.parentSlug || product.slug)
                .then(function() {
                    product.averageRating = $scope.averageRating;
                    if ($scope.reviews) {
                        product.reviewCount = $scope.reviews.length;
                    }
                    setJsonLd(product);
                });

            tracker.productView([product]);
            hasReviewedProduct(product.parentSlug || product.slug) ? $scope.hasAlreadyReviewed = true : $scope.hasAlreadyReviewed = false;
        });
    } else {
        tracker.exception("Product load fail", true);
        alert("Došlo k problému při nahrávání produktu");
    }

    $scope.openLightbox = gallery.openLightbox;
    $scope.closeLightbox = gallery.closeLightbox;


    /**
     * This asynchronously loads product including all the dynamical and interactive functionality provided via angular. Used on prior loading and when switching variants
     * @param  {String}     productUrl  whole api url for product detail to load
     * @param  {Function}   cb          success callback
     */
    function loadProduct(productUrl, cb) {
        variantSelects = [];
        $http.get(productUrl, {cache: true})
            .then(function(data) {
                let product = data.data;
                product.description = $sce.trustAsHtml(product.description);
                meta({
                    title: product.metaTitle || product.name,
                    description: product.metaDescription || product.shortDescription,
                    image: product.image ? product.image.default_big : "",
                });

                // Get selects for variants
                getVariantsSelects(product, function() {
                    // The last select is selecting product's own variants
                    if (product.variants.length > 0) {
                        variantSelects.push({
                            possibilities: product.variants.map(variant => {
                                return  {
                                    variantName: variant.variantName,
                                    name: variant.name,
                                    slug: variant.slug,
                                }}),
                            // This selected variant is either thw one you are on (it was selected manually or you came from a direct link) or default, or null
                            selectedVariant: (function() {
                                let defVar = _find(product.variants, {defaultVariant: true});
                                let selectedVar = _find(product.variants, {slug: product.slug});
                                if (!selectedVar) selectedVar = defVar;
                                return selectedVar ? selectedVar.slug : null;
                            })(),
                            variantType: product.variantType,
                        });
                    }
                    // Last selected variant is used when you go to parent product with a default variant selected.
                    // Then you are in a state where you are on different product than you should be
                    if (variantSelects.length > 0) {
                        let lastSelected = _last(variantSelects).selectedVariant;
                        if (lastSelected && lastSelected !== product.slug) {
                            $scope.selectProduct(lastSelected);
                            return;
                        }
                    }
                    // If variant name is defined for possibilities, use it
                    _forEach(variantSelects, function(n) {
                        _forEach(n.possibilities, function(o) {
                            if (o.variantName) {
                                o.name = o.variantName;
                            }
                        });
                    });
                    $scope.varSels = variantSelects;
                    $scope.variantsLoaded = true;
                    if (cb) cb(product);
                });
            })
            .catch(() => {
                window.location.href = "/404";
            });
    }

    function getCrossSell(productSlug, category) {
        crossSellServ.getCrossSell([productSlug], null)
            .then(function(data) {
                $scope.otherProducts = data;
                tracker.productListView(data, "Product crosssell");
                let swiperOptions = {
                    slidesPerView: 3,
                    // Navigation arrows
                    navigation: {
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        0: {
                          slidesPerView: 1
                        },
                        550: {
                          slidesPerView: 2
                        },
                        1150: {
                          slidesPerView: 3
                        }
                    }
                }

                $timeout(function() {
                    let swiper = new Swiper('#featured-slider', swiperOptions);
                },500);
            });
    }

    /**
     * @param  {Array} parameterValues
     */
    function prepareParameters(parameterValues) {
        let result = [];
        _forEach(parameterValues, function(value) {
            if (!value.parameter.parameterCategory) value.parameter.parameterCategory = {name: "Obecné"};
            let match = _find(result, {parameterCategory: value.parameter.parameterCategory.name});
            if (match) {
                match.parameters.push(value);
            } else {
                result.push({
                    parameterCategory: value.parameter.parameterCategory.name,
                    parameters: [value],
                });
            }
        });
        return result;
    }

    /**
     * Recursivelly loads parents of products up the tree to construct selects for product variants
     * @param  {Object}   product
     * @param  {Function} cb
     */
    function getVariantsSelects(product, cb) {
        if (product.parentSlug) {
            $http.get(productDetailUrl + product.parentSlug, {cache: true})
                .then(function(parent) {
                    parent = parent.data;
                    variantSelects.push({
                        possibilities: parent.variants.map(variant => {
                            return  {
                                variantName: variant.variantName,
                                name: variant.name,
                                slug: variant.slug,
                            }}),
                        selectedVariant: product.slug,
                        variantType: parent.variantType,
                    });
                    getVariantsSelects(parent, cb);
                });
        } else {
            _reverse(variantSelects);
            if (cb) cb();
        }
    }

    $scope.selectProduct = function(slug) {
        $scope.variantsLoaded = false;
        $scope.st.go('productDetail', {product: slug}); // TODO Sadly ui router v1.0.20 doesnt allow to change state without reload
        // loadProduct(productDetailUrl + slug, function(product) {
        //     getCrossSell(product.id);
        //     tracker.productView([product]);
        //     $scope.product = product;
        //     $scope.variantsLoaded = true;
        // });
    };

    $scope.showVariantsPanel = function() {
        if ($scope.product) return $scope.product.parentSlug || $scope.product.variants.length > 0;
    };

    $scope.selectVariants = function() {
        growl.error('Vyberte prosím variantu produktu.');
        return false;
    };

    // REVIEWS
    /**
     * @param {String} productSlug
     * @returns {Promise}
     */
    function getReviews(productSlug) {
        let deferred = $q.defer();
        if (productSlug) {
            $http.get("/api/v1/eshop/product/product/"+productSlug+"/reviews")
                .then(function(data) {
                    if (data.data.length) {
                        let ratings = data.data.map(r => r.score);
                        let avg = ratings.reduce((a,b) => a + b, 0) / ratings.length;
                        $scope.averageRating = avg;
                        $scope.reviews = data.data;
                        $scope.hasAlreadyReviewed = hasReviewedProduct(productSlug);
                        deferred.resolve();
                    } else {
                        deferred.resolve();
                    }
                })
                .catch(function() {
                    deferred.reject();

            });
        } else {
            deferred.reject();
        }
        return deferred.promise;
    }

    $scope.sendReview = function() {

        let reviewedProductSlug = $scope.product.parentSlug || $scope.product.slug;
        if (reviewedProductSlug) {
            splash.show();
            $http.post("/api/v1/eshop/product/product/"+reviewedProductSlug+"/review", {
                name: $scope.review.name,
                email: $scope.review.email,
                text: $scope.review.text,
                score: $scope.review.score,
            }).then(function() {
                addReviewedProduct(reviewedProductSlug);
                growl.info("Vaše hodnocení bylo úspěšně odesláno. Děkujeme, že jste se podělili o Vaše dojmy a můžeme tak nadále vylepšovat naši nabídku :)");
                $scope.closeReviewForm();
                getReviews(reviewedProductSlug);
                splash.hide();
            }).catch(function() {
                growl.error("Vaše hodnocení jsme nezpracovali. Třeba už jste tento produkt hodnotili, třeba je to chyba u nás...");
                splash.hide();
            });
        }
    };

    function hasReviewedProduct(reviewedProductSlug) {
        let revProds = localStorage.getItem("revProds");
        if (revProds) {
            revProds = JSON.parse(revProds);
            return revProds.includes(reviewedProductSlug);
        }
        return false;
    }

    function addReviewedProduct(reviewedProductSlug) {
        let revProds = localStorage.getItem("revProds");
        if (revProds) {
            revProds = JSON.parse(revProds);
            revProds.push(reviewedProductSlug);
            localStorage.setItem("revProds", JSON.stringify(revProds));
        } else {
            localStorage.setItem("revProds", JSON.stringify([reviewedProductSlug]));
        }
    }

    $scope.openReviewForm = () => {
        $scope.showReviewPopup = true;
    };

    $scope.closeReviewForm = () => {
        $scope.showReviewPopup = false;
    };

    $scope.calculateRatingWidth = function(val) {
        if (val) {
            return val/5*100 + "%";
        }
    };

    $scope.addToCart = function(product, source, amount) {
        cart.addToCart(product, amount)
            .then(function() {

                product.acquisition = window.location.pathname;

                if (amount) {product.amount = amount;}
                tracker.addToCart([product], source);

                // growl.info("<i class='fa fa-check' aria-hidden='true'></i> Přidáno do košíku", 5000);
                cartPopup.open(getDeepestCategory(product.category));

                $scope.$broadcast('cartUpdated');
                // $scope.st.go("order.cart");

            })
            .catch(function(data) {
                tracker.exception("Add to cart failed", false);
                growl.error(data);
                // console.log(data);
            });
    };

    function getDeepestCategory(product) {
        if (product.parent) {
            return getDeepestCategory(product.parent);
        } else {
            return product.slug;
        }
    }

    $scope.askForm = {};

    $scope.askProduct = function() {
        $scope.askForm.product = $scope.product.name;
        var postConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
            data: $scope.askForm
        };
        splash.show();
        $http.post('/eshop/productForm', false, postConfig)
            .then(function() {
                splash.hide();
                growl.info('Zpráva byla odeslána', 5000);
                $scope.askForm = {};
                $scope.productform.$setPristine();
            })
            .catch(function() {
                splash.hide();
                growl.error('Při odeslání došlo k chybě. Může to být způsobeno neplatnými daty ve formuláři. Pokuste se data opravit a případně nás kontaktujte telefonicky.');
            });

        Anylytics.ecommerce.gevent("conversion", {category: 'contact', value: $scope.product.price, label: 'product-availability'});
    };

    $scope.requestProduct = function() {
        const postConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        $scope.requestProductForm.message = $scope.product.name;

        splash.show();
        $http.post('/api/v1/contact/productRequest', $scope.requestProductForm, postConfig)
            .then(function() {
                splash.hide();
                $("form")[0].reset();
                growl.info('Zpráva byla odeslána', 5000);
                $scope.requestProductForm = {};
                $scope.formErrors = {};
                tracker.event("conversion", {category: 'contact', value: 500, label: 'request-product'});
                $scope.productForm.$setPristine();
                $scope.requestSent = true;
            })
            .catch(function(data) {
                splash.hide();
                if (data.status === 400) {
                    tracker.exception("request-product-form-error", false);
                    growl.error('Zadali jste nesprávné údaje do formuláře.');
                    $scope.formErrors = data.data.errors;
                } else {
                    tracker.exception("request-product-form-error", true);
                    growl.error('Při odeslání došlo k chybě. Kontaktujte nás prosím telefonicky.');
                }

            });

    }

    $scope.requestSentReset = function() {
        $scope.requestSent = false;
    }

    // JSON-LD
    function setJsonLd(product) {
        // Without time limited offers, just set the price offer valid till the end of current month
        let today = new Date();
        let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
        lastDayOfMonth = lastDayOfMonth.toISOString().substring(0,10);
        $scope.jsonld = {
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": product.category.name + " " + product.name,
            "sku": product.slug,
            "logo": "https://www.greenjungle.cz/build/assets/logo.png",
            "image": product.image ? product.image.default_big || product.image.reference : '',
            "url": window.location.href,
            "description": $('<div/>').html($sce.valueOf(product.description)).text().replace(/<\/?[^>]+(>|$)/g, ""),
            "category": product.category.name,
            "brand": {
                "@type": "Thing",
                "name": product.brand ? product.brand.name : "Cristine Stylové květiny"
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue" : product.averageRating ? product.averageRating.toString() : "5",
                "ratingCount": product.reviewCount ? product.reviewCount.toString() : "1",
                "reviewCount": product.reviewCount ? product.reviewCount.toString() : "1"
            },
            "offers": {
                "@type": "Offer",
                "url": "https://www.greenjungle.cz/produkt/" + product.slug,
                "priceCurrency": "CZK",
                "price": product.price.toString(),
                "priceValidUntil": lastDayOfMonth,
                "itemCondition": "http://schema.org/NewCondition",
                "availability": "http://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "Cristine Stylové květiny"
                }
            }
        };
    }

}]);
