require('../controllers/home');
require('../controllers/products-category');
require('../controllers/products-results');
require('../controllers/product-detail');
require('../controllers/products-search');
require('../controllers/interior');
require('../controllers/green-walls');
require('../controllers/wedding');
// require('../controllers/bouquet');
require('../controllers/configurator');
require('../controllers/configurator-moss');
require('../controllers/contact');
require('../controllers/about');
require('../controllers/order');
require('../controllers/cart');
require('../controllers/shipping');
require('../controllers/customer');
require('../controllers/conditions');
require('../controllers/payment-created');
require('../controllers/payment-failed');
require('../controllers/payment-response');
require('../controllers/paypal-payment-cancel');
require('../controllers/paypal-payment-response');
require('../controllers/thanks');
require('../controllers/cookies');
require('../controllers/personal');

import home from '../../views/home.html';
import products from '../../views/products.html';
import productsFiltered from '../../views/products-filtered.html';
import productDetail from '../../views/product-detail.html';
import productSearch from '../../views/product-search.html';
import interior from '../../views/interior.html';
import interiorMaintenance from '../../views/interior-maintenance.html';
import interiorSale from '../../views/interior-sale.html';
import interiorRent from '../../views/interior-rent.html';
import greenWalls from '../../views/green-walls.html';
import greenWallsMoss from '../../views/green-walls-moss.html';
import greenWallsVertical from '../../views/green-walls-vertical.html';
import greenWallsLive from '../../views/green-walls-live.html';
import wedding from '../../views/wedding.html';
// import bouquet from '../../views/bouquet.html';
import configurator from '../../views/configurator.html';
import configuratorMoss from '../../views/configurator-moss.html';
import contact from '../../views/contact.html';
import about from '../../views/about.html';
import orderWrapper from '../../views/order-wrapper.html';
import cart from '../../views/cart.html';
import orderShipping from '../../views/order-shipping.html';
import customer from '../../views/customer.html';
import conditions from '../../views/conditions.html';
import paymentResponse from '../../views/payment-response.html';
import thanks from '../../views/thanks.html';
import paymentCreated from '../../views/payment-created.html';
import paymentFailed from '../../views/payment-failed.html';
import cookies from '../../views/cookies.html';
import personal from '../../views/personal.html';
import e404 from '../../views/404.html';

export default {
    'home': {
        url: '/',
        templateUrl: home,
        controller: 'HomeCtrl',
    },
    'productsFilter': {
        url: '/eshop/:category',
        templateUrl: products,
        controller: 'ProductsCategoryCtrl',
    },
    'productsFilter.filtering': {
        url: '/filtr?strana&znacka&razeni&smer&cenaOd&cenaDo&hledani&parametry',
        templateUrl: productsFiltered,
        controller: 'ProductsResultCtrl',
    },
    'productDetail': {
        url: '/produkt/:product',
        templateUrl: productDetail,
        controller: 'ProductDetailCtrl',
    },
    'productSearch': {
        url: '/search/:phrase',
        templateUrl: productSearch,
        controller: 'ProductSearchCtrl',
    },
    'interior': {
        url: '/pokojove-rostliny',
        templateUrl: interior,
        controller: 'InteriorCtrl',
    },
    'interiorMaintenance': {
        url: '/pokojove-rostliny/pravidelna-udrzba-rostlin',
        templateUrl: interiorMaintenance,
        controller: 'InteriorCtrl',
    },
    'interiorSale': {
        url: '/pokojove-rostliny/prodej-rostlin-a-kvetinacu',
        templateUrl: interiorSale,
        controller: 'InteriorCtrl',
    },
    'interiorRent': {
        url: '/pokojove-rostliny/pronajem-rostlin',
        templateUrl: interiorRent,
        controller: 'InteriorCtrl',
    },
    'greenWalls': {
        url: '/zelene-steny',
        templateUrl: greenWalls,
        controller: 'GreenWallsCtrl',
    },
    'greenWallsMoss': {
        url: '/zelene-steny/mechove-steny',
        templateUrl: greenWallsMoss,
        controller: 'GreenWallsCtrl',
    },
    'greenWallsVertical': {
        url: '/zelene-steny/vertikalni-zahrady',
        templateUrl: greenWallsVertical,
        controller: 'GreenWallsCtrl',
    },
    'greenWallsLive': {
        url: '/zelene-steny/live-picture-mechove-obrazy',
        templateUrl: greenWallsLive,
        controller: 'GreenWallsCtrl',
    },
    'wedding': {
        url: '/svatebni-kytice-a-dekorace',
        templateUrl: wedding,
        controller: 'WeddingCtrl',
    },
    // 'bouquet': {
    //     url: '/stylove-kytice',
    //     templateUrl: bouquet,
    //     controller: 'BouquetCtrl',
    // },
    'configurator': {
        url: '/konfigurator-kytice',
        templateUrl: configurator,
        controller: 'ConfiguratorCtrl',
    },
    'configuratorMoss': {
        url: '/konfigurator-mechovych-obrazu',
        templateUrl: configuratorMoss,
        controller: 'ConfiguratorMossCtrl',
    },
    'contact': {
        url: '/kontakt',
        templateUrl: contact,
        controller: 'ContactCtrl',
    },
    'about': {
        url: '/o-nas',
        templateUrl: about,
        controller: 'AboutCtrl',
    },
    'order': {
        url: '/objednavka',
        templateUrl: orderWrapper,
        controller: 'OrderCtrl',
        abstract: true,
    },
    'order.cart': {
        url: '/kosik',
        templateUrl: cart,
        controller: 'CartCtrl',
    },
    'order.shipping': {
        url: '/doprava-platba',
        templateUrl: orderShipping,
        controller: 'OrderShippingCtrl'
    },
    'order.customer': {
        url: '/dodaci-udaje',
        templateUrl: customer,
        controller: 'OrderCustomerCtrl'
    },
    'conditions': {
        url: '/obchodni-podminky',
        templateUrl: conditions,
        controller: 'ConditionsCtrl',
    },
    'paymentResponse': {
        url: '/gopay-odpoved?id',
        templateUrl: paymentResponse,
        controller: 'PaymentResponseCtrl',
    },
    'thanks': {
        url: '/dekujeme-za-objednavku',
        templateUrl: thanks,
        controller: 'ThanksCtrl',
    },
    'paymentCreated': {
        url: '/platba-vytvorena?orderId',
        templateUrl: paymentCreated,
        controller: 'PaymentCreatedCtrl',
    },
    'paymentFailed': {
        url: '/chyba-platby?orderId',
        templateUrl: paymentFailed,
        controller: 'PaymentFailedCtrl',
    },
    'cookies': {
        url: '/cookies',
        templateUrl: cookies,
        controller: 'CookiesCtrl',
    },
    'personal': {
        url: '/osobni-udaje',
        templateUrl: personal,
        controller: 'PersonalCtrl',
    },
    'notFound': {
        url: '/404',
        templateUrl: e404,
        controller: function() {window.documentReadyToRender = true;},
    },
}
