import $ from 'jquery';

window.app.service('SplashServ', ['$q', '$timeout', function($q, $timeout) {
        let prom = null;

        this.show = function() {
            let deferred = $q.defer();
            $('#splash-screen').fadeIn(200);
            $timeout(function() {
                $('#content-wrapper').fadeOut(200);
            }, 200);
            $timeout(function() {
                deferred.resolve(); // <-- This has to be called!
            }, 500);
            return deferred.promise;
        };

        this.loading = function() {
            let deferred = $q.defer();
            $('#splash-screen').fadeIn(200);
            $timeout(function() {
                deferred.resolve(); // <-- This has to be called!
            }, 500);
            return deferred.promise;
        };

        this.hide = function() {
            let deferred = $q.defer();
            $timeout(function() {
                $('#content-wrapper').fadeIn(200);
            }, 300);
            $timeout(function() {
                $('#splash-screen').fadeOut(300);
                deferred.resolve(); // <-- This has to be called!
            }, 500);
            return deferred.promise;
        };
    }]);
