/**
 * Service for recommending products in given places
 * @version 1.0-SPA
 */

import _isNumber from 'lodash/isNumber';
import _some from 'lodash/some';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';

window.app.service('CrossSellServ', ['$http', '$q',
    function($http, $q) {

        const crossSellUrl = "/api/v1/eshop/product/similar-products";

        /**
         * @param  {Array}      productSlugs      array of products for which similar products will be found
         * @param  {Integer}    productCount    How many products to find
         * @return {Promise}
         */
        this.getCrossSell = function(productSlugs, productCount) {
            let deferred = $q.defer();
            if (!_isNumber(productCount)) productCount = 10;
            $http.get(crossSellUrl+"?limit="+productCount+"&products="+productSlugs.join(","))
                .then(function(data) {
                    return deferred.resolve(data.data.payload);
                });
            return deferred.promise;
        };

        this.getUpSell = function(products, product) {
            // TODO
        };

        this.getArticleProductSell = function(article, products) {
            // Assign similarity score to each product showing how many tags they have in common with the article
            products.forEach(o => {
                o.similarity = 0;
                article.tags.forEach(tag => {
                    if (_some(o.tags, function(p) {return p === tag;})) {
                        if (o.similarity) {
                            o.similarity++;
                        } else {
                            o.similarity = 1;
                        }
                    }
                });
            });

            return _reverse(_sortBy(products, o => o.similarity)).slice(0,3);
        };
    }
]);
