import * as tracker from '../utils/commerce';
import $ from 'jquery';
import _some from 'lodash/some';
import _find from 'lodash/find';
import setting from '../utils/configurator-setting';

window.app.controller('ConfiguratorCtrl',
    ['$scope', 'PageMetaServ', '$http', 'GrowlServ', 'SplashServ', '$sce', 'ConfiguratorServ', 'ShippingServ',
        function($scope, meta, $http, growl, splash, $sce, configuratorServ, shippingServ) {

            meta({
                title: "Konfigurátor kytic Cristine - Sestavte si vlastní kytici",
                description: "",
            });

            const emptyForm = {};
            $scope.order = {};
            $scope.criteria = {};

            $scope.occasions = setting.occasions;
            $scope.styles = setting.styles;
            $scope.budgets = setting.budgets;
            $scope.packaging = setting.packaging;
            $scope.colors = setting.colors;
            $scope.flowers = setting.flowers;

            $scope.confStage = 1;

            $scope.stepBack = function() {
                $scope.confStage--;
            };
            $scope.stepForward = function() {
                $scope.confStage++;
            };
            $scope.goToStage = function(num) {
                if ($scope.canProceed(num)) $scope.confStage = num;
            };

            $scope.selectCriteria = function(prop, val, preventStep) {
                $scope.criteria[prop] = val;
                if (prop === 'budget') {
                    $scope.criteria.budget = val;
                    $scope.criteria.price = _.find(setting.budgets, function(o) {return o.slug === val;}).price;
                }
                if (!preventStep) {
                    $scope.stepForward();
                }
            };

            $scope.hint = {};
            $scope.setHint = function(prop, val) {
                let par = _.find($scope[prop], {slug: val});
                if (par && par.hint) {
                    $("#hint-"+prop).fadeOut(300, function() {
                        $("#hint-"+prop).html(par.hint);
                        $("#hint-"+prop).fadeIn(300);
                    });
                }
            };

            // Rules for disabling certain combinations
            const forbiddens = {
                'packaging': {
                    'cone': {
                        'budget': ['p1250', 'p1500']
                    },
                    'box': {
                        'budget': ['p500']
                    },
                },
                // 'budget': {
                //     'p700': {
                //         'packaging': ['box'],
                //     },
                //     'p900': {
                //         'packaging': ['box'],
                //     },
                //     'p1500': {
                //         'packaging': ['cone'],
                //     },
                //     'pMax': {
                //         'packaging': ['cone', 'box'],
                //     },
                // },
            };
            $scope.isDisabled = function(crtType, val) {
                if (!forbiddens[crtType]) return false;
                if (!forbiddens[crtType][val]) return false;

                let banFound = false;
                _.forEach(forbiddens[crtType][val], function(value, prop) {
                    if(_.some(value, function(x) {return x === $scope.criteria[prop];})) {
                        banFound = true;
                    }
                });
                return banFound;
            };

            $scope.canProceed = function(stage) {
                switch (stage) {
                    case 1:
                        return true;
                    case 2:
                        return !!($scope.criteria.occasion || $scope.criteria.customOccasion);
                    case 3:
                        return $scope.canProceed(2);
                    case 4:
                        return $scope.canProceed(2) && (!!($scope.criteria.budget || $scope.criteria.customBudget));
                    case 5:
                        return $scope.canProceed(4) && (!!$scope.criteria.packaging);
                    case 6:
                        return $scope.canProceed(5);
                    default:
                        return true;
                }

            };

            $scope.getPackageTypes = function(packaging) {
                if (packaging) {
                    return _.find(setting.packaging, function(o) {return o.slug === packaging;}).packageTypes;
                }
            };

            let payments = [];
            $scope.shippings = [];

            shippingServ.getShippingMethods()
                .then(function(data) {
                    $scope.shippings = data;
                });

            shippingServ.getPaymentMethods()
                .then(function(data) {
                    payments = data;
                    $scope.payments = data;
                });

            $scope.getPayments = function() {
                if (!$scope.order.shipping && payments.length > 0 && $scope.shippings.length > 0) {
                    return payments;
                } else {
                    if (_.find($scope.shippings, {slug: $scope.order.shipping})) {
                        return _.filter(payments, function(o) {return _.some(_.find($scope.shippings, {slug: $scope.order.shipping}).parameters.possible_payments, function(n) {return n === o.slug;});});
                    } else {
                        return payments;
                    }
                }
            };

            $scope.shippingPrice = function() {
                return shippingServ.getShippingPrice($scope.order.shipping, $scope.order.payment, 1);
            };

            $scope.sendDemand = function() {

                splash.show();
                configuratorServ.sendConfigurator($scope.order, $scope.criteria)
                    .then(function(data) {
                        splash.hide();
                        let prodIds = [];
                        tracker.event("conversion", {category: 'configurator', value: 500, label: 'configurator-sent'});

                        // Redirect to gopay
                        if (data.data.gatewayUrl) {
                            window.location.href = data.data.gatewayUrl;
                            return;
                        }
                        growl.info("Vaše objednávka byla úspěšně odeslána!");
                        $scope.st.go("thanks");
                    })
                    .catch(function() {
                        splash.hide();
                        growl.error("Objednávku se nezdařilo odeslat. Prosím překontrolujte zadané údaje a případně nás kontaktujte.");
                    });

            };

        }
        ]);
