window.app.service('GrowlServ', [
    '$timeout', '$sce',
    function($timeout, $sce) {

    let model = {clear: function() {
            reset();
        }
    };
    reset();

    this.getModel = function() {
        return model;
    };

    /**
     * Displays warning box.
     * @param  {String} message
     */
    this.warning = function(message, time) {
        growl(message, 'warning', time);
    };

    // TODO Info growl may deserve a special box, where multiple info messages would be called out
    /**
     * Displays info box.
     * @param  {String} message
     * @param  {Integer} time
     */
    this.info = function(message, time) {
        growl(message, 'info', time);
    };

    /**
     * Displays error box.
     * @param  {String} message
     */
    this.error = function(message) {
        growl(message, 'error');
    };

    this.clear = function() {
        reset();
    };

    function reset() {
        model.text = '';
        model.button = false;
        model.classes = [];
    }

    function growl(message, type, time) {
        model.text = $sce.trustAsHtml(message);
        model.classes = ['active', type];
        if (time) {
            $timeout(function() {reset();}, time);
        } else {
            model.button = true;
        }
    }

}]);
