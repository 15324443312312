require('../_base/splash-serv');
require('../_base/confirmation-serv');
require('../_base/cart-serv');
require('../_base/growl-serv');
require('../_base/cartpopup-serv');
require('../directives/any-confirm');
require('../directives/any-splash');

import {checkLocalConsent} from "../utils/consents";
import $ from 'jquery';
import * as tracker from '../utils/commerce';
import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';

window.app.controller('RootCtrl',
    ['$scope', '$timeout', '$state', 'SplashServ', 'GrowlServ', 'CartPopupServ', 'ConfirmationServ', '$interval', '$http', 'PageMetaServ', 'CartServ', '$transitions',
    function($scope, $timeout, $state, splash, growl, cartPopup, confirmation, $interval, $http, meta, cart, $transitions) {
        $scope.growl = growl.getModel();
        $scope.cartPopup = cartPopup.getModel();
        $scope.confirmation = confirmation.getModel();
        $scope.st = $state;

        checkLocalConsent(null);

        $interval(function() {
            animateBubbleCart();
        }, 18000);

        $scope.menuActive = false;
        $scope.toggleMenu = function(bool) {
            if (typeof bool === "boolean") {
                $scope.menuActive = bool;
            } else {
                $scope.menuActive = !$scope.menuActive;
            }
        };

        $scope.removeProduct = function(ind) {
            if (ind > -1) {
                cart.removeItem(ind);
                $scope.$broadcast('cartUpdated');
                growl.info("Položka byla z košíku odebrána.", 5000);
            } else {
                cart.clearCart();
                $scope.$broadcast('cartUpdated');
                growl.info("Košík byl vyprázdněn.", 5000);
            }
        };

        $scope.updateCart = function() {
            cart.setCart($scope.cart);
        };

        $scope.cart = cart.getCart();

        $scope.cartPrice = function() {
            return cart.getCartPrice();
        };
        $scope.vatCart = function() {
            return cart.getVatCart();
        };

        $scope.setFilterParams = categorySlug => {
            $scope.$broadcast("setFilter", {categorySlug});
        };

        $scope.$on('cartUpdated', function() {
            $scope.cart = cart.getCart();
        });

        $scope.scrollTo = function(where, noOffset) {
            if (_isNumber(where)) {
                $('html, body').animate({scrollTop: where}, '800', 'swing');
            } else {
                if (_isString(where) && $("#"+where)) {
                    if (noOffset) {
                        $('html, body').animate({
                            scrollTop: $("#"+where).offset().top
                        }, '600', 'swing');
                    } else {
                        $('html, body').animate({scrollTop: $("#"+where).offset().top - 60}, '600', 'swing');
                    }
                }
            }
        };

        function animateBubbleCart() {
            $('.circle-1').addClass('bubble');
            setTimeout(function() {
                $('.circle-2').addClass('bubble');
            }, 500);
            setTimeout(function() {
                $('.circle-3').addClass('bubble');
            }, 1000);
            setTimeout(function() {
                $('.circle-3, .circle-2, .circle-1').removeClass('bubble');
            }, 3000);
        }

        $transitions.onStart({}, function () {
            $("html, body").animate({scrollTop: 0}, 400, "swing");
        })

        // $scope.$on('$stateChangeStart',
        //     function(event, toState, toParams, fromState, fromParams) {
        //         if (($(document).scrollTop() > window.innerHeight/3) && !(toState.name === "productsFilter.filtering" && fromState.name === "productsFilter.filtering")) {
        //             event.preventDefault();
        //             $("html, body").animate({scrollTop: 0}, 400, "swing", function() {
        //                 $state.go(toState, toParams);
        //             });
        //         }
        //     });
    }]);
