window.app.controller('PaymentFailedCtrl', [
    '$scope',
    'PageMetaServ',
    '$http',
    function($scope, meta, $http) {

        meta({
            title: "Platbu se nepodařilo dokončit | Cristine Stylové květiny",
            description: '',
            robots: "nofollow,noindex"
        });

        $scope.message = "";

        if ($scope.st.params.orderId) {
            $http.get('/payment/methods/gopay/status', {orderId: $scope.st.params.orderId})
                .then(function(data) {
                    $scope.message = "Bohužel se nepodařilo platbu přes bránu GoPay dokončit. " + data.data.state;
                })
                .catch(function() {
                    $scope.message = "Omlouváme se, bohužel při platbě došlo k chybě. Vyzkoušejte prosím objednat znovu, případně nás kontaktujte na info@greenjungle.cz.";
                });

        } else {
            $scope.message = "Omlouváme se, bohužel při platbě došlo k chybě. Vyzkoušejte prosím objednat znovu, případně nás kontaktujte na info@greenjungle.cz.";
        }

    }]);
