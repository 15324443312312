export default {
    size: [
        {
            slug: "23x23",
            hint: "<h3>Ukázka malého<br>mechového obrazu</h3><div class='row'><img src='../../images/configurator-moss/23.jpg' alt='' /></div>"
        }, {
            slug: "30x40",
            hint: "<h3>Ukázka středního<br>mechového obrazu</h3><div class='row'><img src='../../images/configurator-moss/30.jpg' alt='' /></div>"
        }, {
            slug: "50x50",
            hint: "<h3>Ukázka velkého<br>mechového obrazu</h3><div class='row'><img src='../../images/configurator-moss/50.jpg' alt='' /></div>"
        },
    ],
    color: [
        {
            slug: "bila",
            name: "Bílá",
            hint: "<div class='row'><img src='../../images/configurator-moss/bila.jpg'></div>"
        },{
            slug: "briza",
            name: "Bříza",
            hint: "<div class='row'><img src='../../images/configurator-moss/briza.jpg'></div>"
        },{
            slug: "hneda",
            name: "Hnědá",
            hint: "<div class='row'><img src='../../images/configurator-moss/hneda.jpg'></div>"
        }
    ],
    moss: [
        {
            slug: "plochy-mech",
            name: "Plochý",
            hint: "<h3>Ukázka</h3><div class='row'><img src='../../images/configurator-moss/plochy.jpg'></div>"
        }, {
            slug: "kopeckovy-mech",
            name: "Kopečkový",
            hint: "<h3>Ukázka</h3><div class='row'><img src='../../images/configurator-moss/kopeckovy.jpg'></div>"
        }, {
            slug: "plochý-a-kopeckovy-mech",
            name: "Plochý a kopečkový",
            hint: "<h3>Ukázka</h3><div class='row'><img src='../../images/configurator-moss/plochy-kopeckovy.jpg'></div>"
        }, {
            slug: "lisejnik",
            name: "Lišejník",
            hint: "<h3>Ukázka</h3><div class='row'><img src='../../images/configurator-moss/lisejnik.jpg'></div>"
        }, {
            slug: "kopeckovy-mech-a-lisejnik",
            name: "Kopečkový a lišejník",
            hint: "<h3>Ukázka</h3><div class='row'><img src='../../images/configurator-moss/kopeckovy-lisejnik.jpg'></div>"
        }, {
            slug: "mix-mechu",
            name: "Mix mechů",
            hint: "<h3>Ukázka</h3><div class='row'><img src='../../images/configurator-moss/mix.jpg'></div>"
        }, {
            slug: "mix-mechu-a-drevo",
            name: "Mix mechů a dřeva",
            hint: "<h3>Ukázka</h3><div class='row'><img src='../../images/configurator-moss/mix-drevo.jpg'></div>"
        },
    ],
}
