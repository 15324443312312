import _forEach from 'lodash/forEach';
import _remove from 'lodash/remove';

window.app.service('ConfiguratorMossServ', ['$http', '$q',
    function($http, $q) {

        const mossConfig = [];

        this.sendConfiguratorOrder = function(order, criteria) {
            const deferred = $q.defer();
            order.criteria = criteria;
            const postConfig = {
                data: order,
            };
            $http.post('/api/v1/eshop/configurator/moss', {}, postConfig)
                .then(function(data) {
                    deferred.resolve(data);
                })
                .catch(function() {
                    deferred.reject();
                });
            return deferred.promise;
        };

        /**
         * Demand is a lighter object than an order and it doesn't carry shipping and payment
         * The criteria are the same as in order but customer info is just first name, last name, email, phone number and a note.
         * @param  {Object}     order     customer info
         * @param  {Object}     criteria  configurator criteria
         * @return {Promise}
         */
        this.sendConfiguratorDemand = function(order, criteria) {
            const deferred = $q.defer();

            if (criteria.customSize) {
                criteria.size = criteria.customSize;
                delete criteria.customSize;
            }
            if (criteria.customMoss) {
                criteria.moss = criteria.customMoss;
                delete criteria.customMoss;
            }
            order.criteria = criteria;
            const postConfig = {
                data: order,
            };
            $http.post('/api/v1/eshop/configurator/moss/demand', {}, postConfig)
                .then(function(data) {
                    deferred.resolve(data);
                })
                .catch(function() {
                    deferred.reject();
                });
            return deferred.promise;
        };

        this.getMossConfig = function () {
            const deferred = $q.defer();

            $http.get("/eshop/product/configurator/moss", null, {cache: true})
                .then(function(data) {
                    const mossConfig = data.data;
                    deferred.resolve(mossConfig);
                })
                .catch(function() {
                    deferred.reject();
                });

            return deferred.promise;
        };

        this.getConfiguratorShippingMethods = function () {
            const deferred = $q.defer();

            $http.get("/eshop/delivery/methods", null, {cache: true})
                .then(function(data) {
                    _forEach(data.data, function (shippingMethod) {
                        _remove(shippingMethod.parameters.possible_payments, function (paymentMethod) {
                            return new RegExp(/gopay/).test(paymentMethod);
                        });
                    });

                    const shippingMethods = data.data;
                    deferred.resolve(shippingMethods);
                })
                .catch(function() {
                    deferred.reject();
                });

            return deferred.promise;
        };

        this.getConfiguratorPaymentMethods  = function () {
            const deferred = $q.defer();

            $http.get("/eshop/payment/methods", null, {cache: true})
                .then(function(data) {
                    _remove(data.data, function (method) {
                        return new RegExp(/(cash_on_delivery|gopay)/).test(method.slug);
                    });

                    const paymentMethods = data.data;
                    deferred.resolve(paymentMethods);
                })
                .catch(function() {
                    deferred.reject();
                });

            return deferred.promise;
        };
    }]);
