import * as tracker from '../utils/commerce';
require('../_base/page-meta-serv');
require('../_base/growl-serv');
require('../_base/splash-serv');

window.app.controller('InteriorCtrl',
    ['$scope', 'PageMetaServ', '$http', 'GrowlServ', 'SplashServ',
        function($scope, meta, $http, growl, splash) {

            meta({
                title: "Pokojové rostliny do kanceláří i domovů | Cristine Stylové květiny",
                description: "Květinové výzdoby interiérů - kanceláří, recepcí komerčních prostor i domovů. Profesionální péče a doživotní záruka na rostliny.",
            });

            const refOwl = $(".slider");
            const owlSetup = {
                // Most important owl features
                // Most important owl features
                items: 1,
                dots: false,
                loop: true,
                rewind: true,
                stagePadding: 40,
                margin: 41,

                //Basic Speeds
                navSpeed : 200,
                dotsSpeed : 800,
                rewindSpeed : 1000,

                //Pagination
                pagination : false,
                paginationNumbers: false,

                // Navigation
                nav: true,
                navElement: 'div',
                navText : ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
            };

            const emptyForm = {
                email: '',
                phoneNumber: '',
                message: '',
            };
            $scope.form = Object.assign({}, emptyForm);

            $scope.sendDemand = function() {
                const postConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                splash.show();
                $http.post('/api/v1/contact/interior', $scope.form, postConfig)
                    .then(function() {
                        splash.hide();
                        $("form")[0].reset();
                        growl.info('Zpráva byla odeslána', 5000);
                        $scope.form = Object.assign({}, emptyForm);
                        $scope.formErrors = {};
                        tracker.event("conversion", {category: 'contact', value: 2000, label: 'contact-interior'});
                        $scope.demand.$setPristine();
                    })
                    .catch(function(data) {
                        splash.hide();
                        if (data.status === 400) {
                            tracker.exception("interior-form-error", false);
                            growl.error('Zadali jste nesprávné údaje do formuláře.');
                            $scope.formErrors = data.data.errors;
                        } else {
                            tracker.exception("interior-form-error", true);
                            growl.error('Při odeslání došlo k chybě. Kontaktujte nás prosím telefonicky.');
                        }
                    });
            };

        }
        ]);
