window.app.controller('CookiesCtrl',
    ['$scope', 'PageMetaServ',
        function($scope, meta) {

            meta({
                title: "Práce s údaji o prohlížení",
                description: '',
                robots: "nofollow,noindex"
            });

        }
]);
