import * as tracker from '../utils/commerce';
import $ from 'jquery';
import _some from 'lodash/some';
import _find from 'lodash/find';

window.app.controller('OrderShippingCtrl',
    ['$scope', 'PageMetaServ', 'ShippingServ',
        function($scope, meta, shippingServ) {

            meta({
                title: 'Doprava a platba | Cristine stylové květinářství',
                description: '',
                robots: 'noindex',
            });

            tracker.continueCheckout($scope.cart, 2);

        }
        ]);
