window.app.service('CartPopupServ', [
    '$sce',
    function($sce) {

    let model = {clear: function() {
            reset();
        }
    };
    reset();

    this.getModel = function() {
        return model;
    };

    this.open = function(type) {
        openCartPopup(type);
    };

    this.clear = function() {
        reset();
    };

    function reset() {
        model.classes = [];
        model.type = false;
    }

    function openCartPopup(type) {
        model.classes = ['open'];
        model.type = type;
    }

}]);
