import template from '../../views/partials/product-card.html';
import * as tracker from "../utils/commerce";
require('../_base/cart-serv');
require('../_base/growl-serv');
require('../_base/cartpopup-serv');

window.app.directive('productCard', [
    'CartServ',
    'CartPopupServ',
    'GrowlServ',
    function(cart, cartPopup, growl) {

    const controller = ['$scope', function($scope) {
        $scope.addToCart = function(product, source, amount) {
            cart.addToCart(product, amount)
                .then(function() {

                    product.acquisition = window.location.pathname;

                    if (amount) {product.amount = amount;}
                    tracker.addToCart([product], source);

                    // TODO: Missing parent category, not sure how to figure this one out
                    cartPopup.open(getDeepestCategory(product.category));
                    $scope.$broadcast('cartUpdated');
                    // $scope.st.go("order.cart");

                })
                .catch(function(data) {
                    tracker.exception("Add to cart failed", false);
                    growl.error(data);
                });
        };
    }];

    function getDeepestCategory(product) {
        if (product.parent) {
            return getDeepestCategory(product.parent);
        } else {
            return product.slug;
        }
    }

    const link = function(scope, element, attrs) {
    };

    return {
        templateUrl: template,
        link: link,
        scope: {
            "product": "="
        },
        restrict: "A",
        controller: controller,
    };
}]);
