window.app.service('PageMetaServ', [function() {
    let meta = {};

    return function(metaObj) {
        if (arguments.length === 0) {
            return meta;
        }
        meta.title = metaObj.title;
        meta.description = metaObj.description;
        meta.robots = metaObj.robots || "index, follow";
        meta.socTitle = metaObj.socTitle || metaObj.title;
        meta.socDescription = metaObj.socDescription || metaObj.description;
        meta.image = metaObj.image;
        meta.url = metaObj.url;
        meta.keywords = metaObj.keywords;
    };

}]);
