import * as tracker from '../utils/commerce';
import $ from 'jquery';
import _some from 'lodash/some';
import _find from 'lodash/find';
import setting from '../utils/configurator-moss-setting';
require('../services/configurator-moss')

window.app.controller('ConfiguratorMossCtrl',
    ['$scope', 'PageMetaServ', '$http', 'GrowlServ', 'SplashServ', '$sce', 'ConfiguratorMossServ', 'ShippingServ',
        function($scope, meta, $http, growl, splash, $sce, configuratorServ, shippingServ) {

            meta({
                title: "Konfigurátor mechových obrazů | Cristine",
                description: "",
            });

            const emptyForm = {};
            $scope.order = {};
            $scope.criteria = {};

            $scope.size =  setting.size;
            $scope.color =     setting.color;
            $scope.moss =    setting.moss;

            $scope.confStage = 1;

            $scope.stepBack = function() {
                $scope.confStage--;
            };
            $scope.stepForward = function() {
                $scope.confStage++;
            };
            $scope.goToStage = function(num) {
                if ($scope.canProceed(num)) $scope.confStage = num;
            };

            $scope.selectCriteria = function(prop, val, preventStep) {
                $scope.criteria[prop] = val;
                if (!preventStep) {
                    $scope.stepForward();
                }
            };

            $scope.hint = {};
            $scope.setHint = function(prop, val) {
                const par = _find($scope[prop], {slug: val});
                if (par && par.hint) {
                    $("#hint-"+prop).fadeOut(300, function() {
                        $("#hint-"+prop).html(par.hint);
                        $("#hint-"+prop).fadeIn(300);
                    });
                }
            };

            $scope.setMossHint = function(image_path, name) {
                const selector = "#hint-moss";

                if (image_path) {
                    $(selector).fadeOut(300, function () {
                        $(selector).html(`<h3>Ukázka</h3><div class='row'><img src="${image_path}" alt ="${name}"></div>`);
                        $(selector).fadeIn(300);
                    });
                }
            };

            $scope.canProceed = function(stage) {
                switch (stage) {
                    case 1:
                        return true;
                    case 2:
                        return !!($scope.criteria.size || $scope.criteria.customSize);
                    case 3:
                        return $scope.criteria.color && $scope.canProceed(2);
                    case 4:
                        return $scope.canProceed(2) && $scope.canProceed(3) && ($scope.criteria.moss || $scope.criteria.customMoss);
                    default:
                        return true;
                }

            };

            $scope.getPrice = function(size, moss) {
                if (size && moss) {
                    return moss['price_' + size];
                }
            };

            $scope.getMossPrice = function (size, slug) {
                if (size && slug) {
                    const mc = _find($scope.mossConfig, function (c) {
                        return c.slug === slug;
                    });

                    return mc['price_' + size];
                }
            };

            let payments = [];
            $scope.shippings = [];

            shippingServ.getShippingMethods()
                .then(function(data) {
                    $scope.shippings = data;
                });

            shippingServ.getPaymentMethods()
                .then(function(data) {
                    payments = data;
                    $scope.payments = data;
                });

            $scope.getPayments = function() {
                if (!$scope.order.shipping && payments.length > 0 && $scope.shippings.length > 0) {
                    return payments;
                } else {
                    if (_find($scope.shippings, {slug: $scope.order.shipping})) {
                        return _.filter(payments, function(o) {return _.some(_find($scope.shippings, {slug: $scope.order.shipping}).parameters.possible_payments, function(n) {return n === o.slug;});});
                    } else {
                        return payments;
                    }
                }
            };

            $scope.shippingPrice = function() {
                return shippingServ.getShippingPrice($scope.order.shipping, $scope.order.payment, 1);
            };

            $scope.sendOrder = function() {

                splash.show();
                configuratorServ.sendConfigurator($scope.order, $scope.criteria)
                    .then(data => {
                        splash.hide();
                        const prodIds = [];
                        tracker.event("conversion", {category: 'configurator', value: 500, label: 'configurator-sent'});

                        // Redirect to gopay
                        if (data.data.gatewayUrl) {
                            window.location.href = data.data.gatewayUrl;
                            return;
                        }
                        growl.info("Vaše objednávka byla úspěšně odeslána!");
                        $scope.st.go("thanks");
                    })
                    .catch(function() {
                        splash.hide();
                        growl.error("Objednávku se nezdařilo odeslat. Prosím překontrolujte zadané údaje a případně nás kontaktujte.");
                    });

            };

            $scope.sendOrder = function() {

                splash.show();
                configuratorServ.sendConfiguratorOrder($scope.order, $scope.criteria)
                    .then(data => {
                        splash.hide();
                        const prodIds = [];
                        tracker.event("conversion", {category: 'configurator', value: 500, label: 'configurator-sent'});

                        // Redirect to gopay
                        if (data.data.goPayGatewayUrl) {
                            window.location.href = data.data.goPayGatewayUrl;
                            return;
                        }
                        growl.info("Vaše objednávka byla úspěšně odeslána!");
                        $scope.st.go("thanks");
                    })
                    .catch(function() {
                        splash.hide();
                        growl.error("Objednávku se nezdařilo odeslat. Prosím překontrolujte zadané údaje a případně nás kontaktujte.");
                    });

            };

            $scope.sendDemand = function() {

                splash.show();
                configuratorServ.sendConfiguratorDemand($scope.order, $scope.criteria)
                    .then(function(data) {
                        splash.hide();
                        const prodIds = [];
                        tracker.event("conversion", {category: 'configurator', value: 500, label: 'configurator-sent'});

                        growl.info("Vaše poptávka byla úspěšně odeslána! Brzy se Vám ozveme s nabídkou. Děkujeme :)");
                        $scope.order = {};
                        $scope.criteria = {};
                        $scope.st.go("home");
                    })
                    .catch(function() {
                        splash.hide();
                        growl.error("Poptávku se nepodařilo odeslat. Prosím překontrolujte zadané údaje a případně nás kontaktujte.");
                    });

            };

            let mossConfig = [];

            configuratorServ.getMossConfig()
                .then(function (data) {
                    mossConfig = data;

                    const sizes = ["23x23", "30x40", "50x50"];
                    const mossSizePrice = [];

                    sizes.forEach(size => {
                        const sizePrice = {
                            size: size,
                            min_price: _.min(_.map(mossConfig, 'price_' + size))
                        };
                        mossSizePrice.push(sizePrice);
                    });

                    $scope.mossSizePrice = mossSizePrice;
                    $scope.mossConfig = data;

                })
                .catch(function() {
                    growl.error("Nepodařilo se načíst konfiguraci mechových obrazů. Vyzkoušejte prosím obnovit stránku. Pokud problém přetrvá, prosíme kontaktujte nás.", "Došlo k neočekávané chybě");
                    tracker.exception("configurator-moss-failed", false);
                });
        }
        ]);
