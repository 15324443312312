/**
 * @param {String}     item
 * @param              content
 * @param {Boolean}    sessionStoraged
 */
export function store(item, content, sessionStoraged) {
    // Prepare content for storage
    content = btoa(unescape(encodeURIComponent(JSON.stringify(content))))

    sessionStoraged ? window.sessionStorage.setItem(item, content) : window.localStorage.setItem(item, content);
}

export function retrieve(item) {
    let mem = window.localStorage.getItem(item) || window.sessionStorage.getItem(item);
    if (mem) {
        mem = JSON.parse(decodeURIComponent(escape(atob(mem))));
    }
    return mem;
}
