/**
 * @version  1.1-SPA
 */
import * as tracker from './commerce';
import ACconfig from './config';
import * as loader from './script-loader';

/**
 * Tracking conversion from thankpage
 * @param  {Object} data with following properties
 *         - {Number} cartPrice
 *         - {Array}  cart
 *         - {Number} orderId
 *         - {Number} vat
 *         - {Number} shippingPrice
 */
export default function thankConversion(data) {
    // Google and Facebook conversion
    tracker.purchase(data.orderId, data.cart, data);
    // Seznam conversion
    if (ACconfig.seznamConvId) {
        window.seznam_cId = ACconfig.seznamConvId;
        window.seznam_value = data.cartPrice;
        loader.loadScript("https://www.seznam.cz/rs/static/rc.js");
    }
    // eHub tracking
    if (ACconfig.eHub) {
        loader.loadImage("http://ehub.cz/system/scripts/sale.php?AccountId="+ACconfig.eHub+"&TotalCost="+data.cartPrice+"&OrderID="+data.orderId);
    }
    // Heureka tracking
    if (ACconfig.heureka) {
        let _hrq = _hrq || [];
        _hrq.push(['setKey', ACconfig.heureka]);
        _hrq.push(['setOrderId', data.orderId]);
        data.cart.forEach(n => {
            _hrq.push(['addProduct', n.name, n.price, n.amount]);
        });
        _hrq.push(['trackOrder']);

        loader.loadScript('https://im9.cz/js/ext/1-roi-async.js');
    }
}
