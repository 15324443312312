window.app.controller('PayPalPaymentResponseCtrl', [
    '$scope',
    'PageMetaServ',
    '$http',
    function($scope, meta, $http) {

        meta({
            title: "Ověřujeme stav platby | Cristine Stylové květiny",
            description: '',
            robots: "nofollow,noindex"
        });

        $http.get('/api/v1/eshop/payment/methods/paypal/return', {params: {token: $scope.st.params.token}})
            .then(function(data) {
                if (data.data.status !== 'COMPLETED') {
                    $scope.st.go('paymentCreated');
                } else {
                    $scope.st.go("thanks");
                }
            }, function(data) {
                $scope.st.go("paymentFailed", {orderId: data.data.orderId});
            });

    }]);
