import template from '../../views/partials/header.html';
import _some from 'lodash/some';
import _remove from "lodash/remove";
import _size from "lodash/size";
import noUiSlider from "nouislider";
require('../_base/category-serv');
require('../_base/filter-serv');

window.app.directive('anyheader', function() {

    const controller = ['$scope', 'CategoryServ', 'FilterServ', '$timeout', '$transitions', function($scope, categoryServ, filterServ, $timeout, $transitions) {
        categoryServ.getCategories(false)
            .then(data => {
                let categories = data;
                categories.forEach(n => {
                    categoryServ.getCategories(null, n.slug)
                        .then(data => {
                            n.subcategories = data;
                        });
                });
                $scope.categories = categories;
            })

        $scope.searchGo = function() {
            if (!$scope.quickSearch) {
                return false;
            }
            $scope.st.go('productSearch', {phrase: $scope.quickSearch});
        };

        /**
         * @param   {Object}    category
         * @returns {Boolean}   if the active category belongs to the category or one of its subcategories
         */
        $scope.isOneOfCategories = (category) => {
            let categories = category.subcategories ? category.subcategories.map(n => n.slug) : [];
            categories.push(category.slug);
            return _some(categories, n => n === $scope.st.params.category);
        };

        $scope.filteringParam = {};

        $scope.resetFilter = function() {
            $scope.filteringParam = {};
        };

        $scope.$on("setFilter", (e, opt) => {
            filterServ.getFilterData(opt.categorySlug)
                .then(data => {
                    $scope.parameters = data;
                    $scope.filterLoading = false;
                });
        });

        $scope.isInFilter = (parSlug, value) => {
            return filterServ.isParamActive(parSlug, value)
        };

        function filterByParams() {
            $scope.filterReloading = false;
            $scope.st.go('productsFilter.filtering', {'parametry': filterServ.getEncodedCriteria()});
        }

        let filterTimeout;
        $scope.toggleFilterParam = function(parSlug, parValue) {
            $timeout.cancel(filterTimeout);
            filterServ.toggleFilterParam(parSlug, parValue);
            $scope.filterReloading = false;
            $timeout(function(){
                $scope.filterReloading = true;
            },10);
            filterTimeout = $timeout(filterByParams, 2000); // Reduced timeout for filter
        };

        $scope.removeCriteria = function(slug, value) {
            switch (slug) {
                case "category": break;
                case "brand":
                    $scope.st.go('productsFilter.filtering', {'znacka': '', 'strana': ''});
                    break;
                case "cenaOd":
                    $scope.st.go('productsFilter.filtering', {'cenaOd': '', 'strana': ''});
                    break;
                case "cenaDo":
                    $scope.st.go('productsFilter.filtering', {'cenaDo': '', 'strana': ''});
                    break;
                default: // For any params
                    $scope.toggleFilterParam(slug, value);
                    break;
            }
        };

        const slider = document.getElementById('price-slider'),
            priceMin = document.getElementById('price-from'),
            priceMax = document.getElementById('price-to');

        $scope.setPriceSlider = function(sliderFrom, sliderTo, valueFrom, valueTo) {
            if (!slider.noUiSlider) {
                noUiSlider.create(slider, {
                    start: [sliderFrom, sliderTo],
                    connect: true,
                    range: {
                        'min': sliderFrom,
                        'max': sliderTo
                    }
                });

                // On finished change update filtering
                slider.noUiSlider.on('change', function(values) {
                    $scope.st.go('productsFilter.filtering', {'cenaOd': values[0], 'cenaDo': values[1]});
                });
                slider.noUiSlider.on('update', function (values) {
                    priceMin.innerHTML = values[0];
                    priceMax.innerHTML = values[1];
                });
            }
        };

        // $scope.search = function(reset) {
        //     if (reset) {
        //         $scope.st.go('productsFilter.filtering', {'category': 'vse', 'hledani': ''});
        //         $scope.searchPhrase = "";
        //         $timeout(function() {
        //             $scope.searchFor = "";
        //         }, 200);
        //         return;
        //     }

        //     Anylytics.ecommerce.search($scope.searchPhrase, $scope.activeCategory ? $scope.activeCategory.name : "");

        //     $scope.st.go('productsFilter.filtering', {'category': 'vse', 'strana': '', 'znacka': '', 'cenaOd': '', 'cenaDo': '', 'razeni': '', 'smer': '', 'hledani': encodeURIComponent($scope.searchPhrase)})
        // };
    }];

    const link = function(scope, element, attrs) {
    };

    return {
        templateUrl: template,
        link: link,
        scope: true,
        restrict: "A",
        controller: controller,
    };
});
