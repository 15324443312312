import * as storage from '../utils/store';
require('./cart-serv');

/**
 * Service for saving and retrieving order from localstorage and sending it to backend
 * @version 1.0-SPA
 */
window.app.service('OrderServ', ['CartServ', '$http', '$q',
    function(cart, $http, $q) {
        this.updateOrder = function(order) {
            storage.store('order', order, true);
        };
        this.fetchOrder = function() {
            return storage.retrieve('order');
        };

        this.sendOrder = function(order) {
            let deferred = $q.defer();

            order.orderItems = cart.getCart();

            let postConfig = prepareOrder(order);

            $http.post('/api/v1/eshop/order/cart', postConfig)
                .then(function(data) {
                    deferred.resolve(data);
                }, function(data) {
                    deferred.reject(data);
                });

            return deferred.promise;
        };

        function prepareOrder(order) {
            let mapped = {};
            mapped.billingAddress = order.billingAddress;
            mapped.deliveryAddress = order.shippingAddress;
            mapped.zasilkovnaParcelShopId = order.zasilkovnaParcelShopId;
            mapped.zasilkovnaParcelShopName = order.zasilkovnaParcelShopName;
            mapped.paymentMethod = order.payment;
            mapped.deliveryMethod = order.shipping;
            mapped.note = order.note;
            mapped.orderItems = [];
            mapped.personalDataCollectionConsent = true;
            order.orderItems.forEach(item => {
                mapped.orderItems.push({
                    amount: item.amount,
                    product: item.slug,
                });
            });
            return mapped;
        }
}]);
