import * as store from '../utils/store';
import _isNaN from 'lodash/isNaN';
import _some from 'lodash/some';
import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';
import _pullAt from 'lodash/pullAt';
import _reduce from 'lodash/reduce';

window.app.service('CartServ', ['$q',
    function($q) {

        let cartItems = [];

        this.getCart = function () {
            fetchCart();
            return cartItems;
        };

        this.addToCart = function(product, amount) {
            let deferred = $q.defer();
            if (amount < 1 || _isNaN(amount) || !amount) {
                amount = 1;
            }
            // If the product is already in cart, then just add to the amount.
            if (_some(cartItems, {slug: product.slug})) {
                _find(cartItems, {slug: product.slug}).amount += amount;
                updateCart();
                deferred.resolve("Úspěšně vloženo do košíku");
            } else {
                let cartItem = _cloneDeep(product);
                cartItem.amount = amount;
                cartItems.push(cartItem);
                updateCart();
                deferred.resolve("Úspěšně vloženo do košíku");
            }
            return deferred.promise;
        };

        this.setCart = function(newCart) {
            cartItems = newCart;
            updateCart();
        };

        this.getCartSize = function () {
            fetchCart();
            let result = 0;
            cartItems.forEach((item) => {
                result += item.amount;
            });
            return result;
        };

        this.getCartWeight = function() {
            fetchCart();
            return _reduce(cartItems, function(sum, n) {return sum + (n.weight*n.amount);}, 0);
        };

        this.clearCart = function() {
            cartItems.length = 0;
            updateCart();
        };

        this.removeItem = function(orderItemIndex) {
            let item = _pullAt(cartItems, orderItemIndex)[0];
            updateCart();
            return cartItems;
        };

        this.getCartPrice = function() {
            fetchCart();
            let totalPrice = 0.0;
            cartItems.forEach(item => {
                if (!_isNaN(Number(item.price))) {
                    totalPrice += (item.price * item.amount);
                }
            });
            return Math.round(totalPrice * 100) / 100;
        };

        this.getVatCart = function() {
            fetchCart();
            let totalVat = 0.0;
            cartItems.forEach(item => {
                if (!_isNaN(Number(item.unit_vat))) {
                    totalVat += (item.unit_vat * item.amount);
                }
            });
            return Math.round(totalVat * 100) / 100;
        };

        this.hasFreeShipping = function() {
            fetchCart();
            return _some(cartItems, function(n) {return n.freeDelivery;});
        };

        function updateCart() {
            store.store('cart', cartItems, true);
        }
        function fetchCart() {
            cartItems = store.retrieve('cart') || [];
        }

}]);
