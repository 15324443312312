export default {
    occasions: [
        {
            slug: "birthday",
            hint: "<h3>Narozeninová kytice</h3><p>Podle věku oslavence, který nám můžete sdělit později v poznámce, vybíráme vhodné kombinace květin, které zaručeně potěší.</p><div class='row'><img src='../../images/configurator/narozeniny.jpg' alt='Narozeninová kytice' /></div>"
        }, {
            slug: "noOccasion",
            hint: "<h3>Jen tak</h3><p>Nejvíce potěší květiny právě tehdy, když je nečekáte!</p><div class='row'><img src='../../images/configurator/jen-tak.jpg' alt='Květina pro radost' /></div>"
        }, {
            slug: "love",
            hint: "<h3>Z lásky</h3><p>Ať už se jedná o kytici ve tvaru červeného srdce či dárkovou květinovou krabičku, květiny Vám vždy pomohou říci to, co máte na srdci.</p><div class='row'><img src='../../images/configurator/z-lasky.jpg' alt='kytice z lásky' /></div>"
        }, {
            slug: "thanks",
            hint: "<h3>Poděkování</h3><p>Ať už se jedná o formální kytici či osobní vzkaz, najdeme společně tu pravou kytici pro tuto příležitost.</p><div class='row'><img src='../../images/configurator/podekovani.jpg' alt='Poděkování' /></div>"
        }, {
            slug: "baby",
            hint: "<h3>Narození dítěte</h3><p>Výjimečně se najde krásnější příležitost k oslavám, než je nový přírůstek do rodiny. Panensky čistá kytice je tím pravým dárkem!</p><div class='row'><img src='../../images/configurator/narozeni-ditete.jpg' alt='kytice k narození dítěte' /></div>"
        }, {
            slug: "business",
            hint: "<h3>Formální</h3><p>První dojem je jen jeden, začněte stylově! Pravidelné zasílání květin Vašim klientům doplněné vzkazem? Žádný problém!</p><div class='row'><img src='../../images/configurator/formalni.jpg' alt='Formální kytice' /></div>"
        }, {
            slug: "funeral",
            hint: "<h3>Na rozloučenou</h3><p>Smuteční květiny, dekorace, smuteční věnce - se vzkazem. Pokud hledáte více než květinu, napište nám prosím informace do poznámky, v posledním kroku objednávky.</p><div class='row'><img src='../../images/configurator/smutecni.jpg' alt='Smuteční květina' /></div>"
        },
    ],
    styles: [
        {
            slug: "simple",
            name: "Jednoduchá",
            hint: "<h4>V jednoduchosti je krása</h4><p>Kytice z jednoho druhu květin či květin stejné barvy.</p><div class='row'><img src='../../images/configurator/jednoduchy.jpg'></div>"
        },{
            slug: "elegant",
            name: "Elegantní",
            hint: "<h4>Elegantní kytice</h4><p>Kytice vytvořená z různých květin většinou v dvoubarevném tónu.</p><div class='row'><img src='../../images/configurator/elegantni.jpg'></div>"
        },{
            slug: "colorful",
            name: "Pestrá",
            hint: "<h4>Pestrá kytice</h4><p>Kytice spojující více druhů květin různých barev. DOPORUČUJEME</p><div class='row'><img src='../../images/configurator/pestra.jpg'></div>"
        },{
            slug: "wild",
            name: "Divoká přírodní",
            hint: "<h4>Divoká kytice</h4><p>Divoká a nespoutaná kytice tvořená mnoha druhy květin – neotřelá a stále 100% přírodní </p><div class='row'><img src='../../images/configurator/divoky.jpg'></div>"
        },
    ],
    budgets: [
        {
            slug: "p500",
            name: "499 Kč",
            text: "9 květin",
            price: 499,
            image: require("../../images/configurator/p500.jpg"),
        },{
            slug: "p750",
            name: "749 Kč",
            text: "13 květin dvou druhů",
            price: 749,
            image: require("../../images/configurator/p750.jpg"),
        },{
            slug: "p990",
            name: "990 Kč",
            text: "pestrý výběr 15 květin",
            price: 990,
            recommended: true,
            image: require("../../images/configurator/p990.jpg"),
        },{
            slug: "p1200",
            name: "1199 Kč",
            text: "originální velká kytice",
            price: 1199,
            image: require("../../images/configurator/p1200.jpg"),
        },
    ],
    packaging: [
        {
            slug: "paper",
            name: "Papír",
            image: require("../../images/configurator/papir-general.jpg"),
            packageTypes: [
                {
                    slug: "paper",
                    name: "Papírový obal",
                    image: require("../../images/configurator/papir-general.jpg"),
                },
                {
                    slug: "no-paper",
                    name: "Bez obalu",
                    image: require("../../images/configurator/bez-papiru.jpg"),
                },
                {
                    slug: "necham-na-vas",
                    name: "Nechám na vás",
                    image: require("../../images/configurator/blank.jpg"),
                },
            ],
        },{
            slug: "cone",
            name: "Kornout",
            image: require("../../images/configurator/kornout-general.jpg"),
            packageTypes: [
                {
                    slug: "brown",
                    name: "Hnědý",
                    image: require("../../images/configurator/kornouty/hnedy.jpg"),
                },
                {
                    slug: "pink",
                    name: "Růžový",
                    image: require("../../images/configurator/kornouty/ruzovy.jpg"),
                },
                {
                    slug: "grey",
                    name: "Šedý",
                    image: require("../../images/configurator/kornouty/sedy.jpg"),
                },
            ],
        },{
            slug: "box",
            name: "Krabička",
            image: require("../../images/configurator/krabicka-general.jpg"),
            packageTypes: [
                {
                    slug: "necham-na-vas",
                    name: "Nechám na vás",
                    image: require("../../images/configurator/blank.jpg"),
                }, {
                    slug: "bila-sede-puntiky",
                    name: "Bílá",
                    image: require("../../images/configurator/krabicky/bila-sede-puntiky.jpg"),
                }, {
                    slug: "bila-velka",
                    name: "Bílá velká",
                    image: require("../../images/configurator/krabicky/bila-velka.jpg"),
                }, {
                    slug: "blankytne-modra",
                    name: "Blankytně modrá",
                    image: require("../../images/configurator/krabicky/blankytne-modra.jpg"),
                }, {
                    slug: "cervena-velka",
                    name: "Červená velká",
                    image: require("../../images/configurator/krabicky/cervena-velka.jpg"),
                }, {
                    slug: "elegantni-bezovohneda",
                    name: "Elegantní béžovohnědá",
                    image: require("../../images/configurator/krabicky/elegantni-bezovohneda.jpg"),
                }, {
                    slug: "prirodni",
                    name: "Přírodní",
                    image: require("../../images/configurator/krabicky/prirodni.jpg"),
                }, {
                    slug: "prirodni-pruhy",
                    name: "Přírodní s proužky",
                    image: require("../../images/configurator/krabicky/prirodni-pruhy.jpg"),
                }, {
                    slug: "ruzova",
                    name: "Růžová",
                    image: require("../../images/configurator/krabicky/ruzova.jpg"),
                },
            ],
        },
    ],
    colors: [
        {
            slug: "white",
            name: "bílá"
        },{
            slug: "yellow",
            name: "žlutá"
        },{
            slug: "orange",
            name: "oranžová"
        },{
            slug: "pink",
            name: "růžová"
        },{
            slug: "red",
            name: "červená"
        },{
            slug: "purple",
            name: "fialová"
        },{
            slug: "anything",
            name: "Nechám na Vás"
        },
    ],
    flowers: [
        {
            slug: "roses",
            name: "Růže",
            image: require("../../images/configurator/ruze.jpg"),
        },{
            slug: "tulips",
            name: "Tulipán",
            image: require("../../images/configurator/tulipany.jpg"),
        // },{
        //     slug: "gerberas",
        //     name: "Gerbera",
        //     image: require("../../images/configurator/gerbery.jpg"),
        // },{
        //     slug: "santinas",
        //     name: "Santina",
        //     image: require("../../images/configurator/santiny.jpg"),
        // },{
        //     slug: "chrysantemas",
        //     name: "Chryzantéma",
        //     image: require("../../images/configurator/chryzantemy.jpg"),
        // },{
        //     slug: "lillies",
        //     name: "Lilie",
        //     image: require("../../images/configurator/lilie.jpg"),
        },{
            slug: "anything",
            name: "Nechám na Vás",
        },
    ],
}
