/**
 * @version  1.2-SPA
 */
import ACconfig from './config';
import * as loader from './script-loader';
import _last from 'lodash/last';
import $ from 'jquery';

// SPA specific is the reloading of scripts. Analytics loading is called with each statechange
// so a bypass possibility was needed. For some of the scripts there is just one time load and
// after setting the script as loaded on AC object, it is bypassed next time the loadAnalytics is called
// The object is persisted till the page is reloaded. After the reload all the scripts are loaded too,
// which is desired behavior.
let oneTimeLoaded = {};

let gtagConf = {};

export default function loadAnalytics(consentLevel, successCb, onlyLoad) {

    gtagConf = {
        'allow_display_features': false,
        'page_path': window.location.href.split("#!")[1], // This is ONLY for SPA with #!
    };

    switch (String(consentLevel)) {
        case "0":
            // Basic gtag snippet loaded
            // To be able to exclude people from remarketing list,
            // we have to load this with the denial on special page for excluding from audience
            if (ACconfig.ga || ACconfig.adwords) {
                if (!oneTimeLoaded.gtag) {
                    // Only initialize the gtag
                    loader.loadScript("https://www.googletagmanager.com/gtag/js?id="+ACconfig.ga || ACconfig.adwords);
                    window.dataLayer = window.dataLayer || [];
                    window.gtag = function() { dataLayer.push(arguments); };
                    window.gtag('js', new Date());

                    oneTimeLoaded.gtag = true;
                    $(document).trigger("gtag:loaded");
                }
            }
            break;
        case "1":
            loadAnalytics("0", null, true);
            // Seznam retargeting
            if (ACconfig.seznamRetargeting) {
                window.seznam_retargeting_id = ACconfig.seznamRetargeting;
                loader.loadScript("//c.imedia.cz/js/retargeting.js", null, true);
            }
            break;
        // Statistics accepted
        case "2":
            loadAnalytics("1", null, true);
            // Smartform script
            if (ACconfig.smartformId) {
                loader.loadScript("https://secure.smartform.cz/api/v1/smartform.js", function() {
                    window.smartform.beforeInit = function initialize() {
                        window.smartform.ssl = true;
                        window.smartform.setClientId(ACconfig.smartformId);
                    };
                    window.smartform.afterInit = function() {
                        window.smartform.rebindAllForms(true);
                        if (_last(window.location.host.split(".")) === 'sk') window.smartform.getInstance().setCountry("SK");
                    };
                }, true);
            }
            break;
        // Marketing accepted
        case "3":
            loadAnalytics("2", null, true);
            // MAILKIT EVENT API
            if (ACconfig.mailkitApiId && !oneTimeLoaded.mailkitApi) {
                window._mailkit = window._mailkit || [];
                _mailkit.push(['setAPIID', ACconfig.mailkitApiId]);
                _mailkit.push(['setDomain', window.location.host]);
                loader.loadScript("//u.mailkit.eu/js/jsapi_v2.js");
                oneTimeLoaded.mailkitApi = true;
                $(document).trigger("mailkit:loaded");
            }
            // GTM SNIPPET
            if (ACconfig.gtm && !oneTimeLoaded.gtm) {
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});let f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer',ACconfig.gtm);
                oneTimeLoaded.gtm = true;
            }
            // Facebook tracking pixel
            if (ACconfig.fbPixel && !oneTimeLoaded.fbPixel) {
                (function(f,b,e,v,n,t,s) {
                    if (f.fbq)
                        return;
                    n = f.fbq = function() {
                        if (n.callMethod) {
                            n.callMethod.apply(n,arguments);
                        } else {
                            n.queue.push(arguments);
                        }
                    };
                    if (!f._fbq)
                        f._fbq = n;
                    n.push = n;
                    n.loaded =! 0;
                    n.version = '2.0';
                    n.queue = [];
                    t = b.createElement(e);
                    t.async =! 0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s);
                }) (window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                window.fbq('init', ACconfig.fbPixel);
                oneTimeLoaded.fbPixel = true;
                $(document).trigger("fbPixel:loaded");
            }

            // YANDEX
            if (ACconfig.yandex) {
                (function (d, w, c) {
                    (w[c] = w[c] || []).push(function() {
                        try {
                            w["yaCounter"+ACconfig.yandex] = new Ya.Metrika({
                                id:ACconfig.yandex,
                                clickmap:true,
                                trackLinks:true,
                                accurateTrackBounce:true,
                                webvisor:true
                            });
                        } catch(e) { }
                    });

                    let n = d.getElementsByTagName("script")[0],
                        s = d.createElement("script"),
                        f = function () { n.parentNode.insertBefore(s, n); };
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = "https://mc.yandex.ru/metrika/watch.js";

                    if (w.opera === "[object Opera]") {
                        d.addEventListener("DOMContentLoaded", f, false);
                    } else { f(); }
                })(document, window, "yandex_metrika_callbacks");
            }

            // Turn on remarketing
            if (ACconfig.ga || ACconfig.adwords) gtagConf.allow_display_features = true;
            break;
        default:
            break;
    }

    if (!onlyLoad) {
        if (window.gtag) {
            if (ACconfig.ga) window.gtag('config', ACconfig.ga, gtagConf);
            if (ACconfig.adwords) window.gtag('config', ACconfig.adwords, gtagConf);
        }
        if (window.fbq) window.fbq('track', "PageView");
        if (typeof successCb == "function") {successCb();}
    }
};
