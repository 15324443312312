import $ from 'jquery';
import Swiper, { Navigation, Controller } from 'swiper';

Swiper.use([Navigation, Controller]);

let lightbox, bigGallery, thumbsGallery;
export function createGalleries() {
    bigGallery = new Swiper('#product-big-images', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    thumbsGallery = new Swiper('#product-thumbs-images', {
        slidesPerView: 3,
    });

    thumbsGallery.controller.control = bigGallery;
    bigGallery.controller.control = thumbsGallery;

    lightbox = new Swiper('#popup-gallery-images', {
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

}

export function openLightbox(index) {
    $("#popup-gallery").show();
    lightbox.slideTo(index);
    $(document).unbind("keydown"); // Remove any handlers
    $(document).keydown( function(eventObject) {
        if (eventObject.which === 27) {
            closeLightbox();
        }
        if (eventObject.which === 37) {//left arrow
            lightbox.slidePrev();
        } else if (eventObject.which === 39) {//right arrow
            lightbox.slideNext();
        }
    });
}

export function closeLightbox() {
    $(document).unbind("keydown"); // Remove any handlers
    $("#popup-gallery").fadeOut();
}
