window.app.controller('PayPalPaymentCancelCtrl', [
    '$scope',
    'PageMetaServ',
    function($scope, meta) {
        meta({
            title: "Ověřujeme stav platby | Cristine Stylové květiny",
            description: '',
            robots: "nofollow,noindex"
        });

        $scope.st.go('order.summary');
    }]);
