import template from '../../views/partials/any-tooltip.html';

window.app.directive('anyTooltip', function() {

    const controller = ['$scope', function($scope) {
        $scope.tooltipActive = false;
    }];

    const link = function(scope, element, attrs) {
    };

    return {
        templateUrl: template,
        link: link,
        scope: {
            "message": "@"
        },
        controller: controller,
    };
});
