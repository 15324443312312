import * as tracker from '../utils/commerce';
import $ from 'jquery';
import _some from 'lodash/some';
import _find from 'lodash/find';
import _size from 'lodash/size';

import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

require('../_base/order-serv');
require('../_base/growl-serv');
require('../_base/splash-serv');
require('../_base/confirmation-serv');
require('../_base/cart-serv');
require('../_base/shipping-serv');

window.app.controller('OrderCtrl', [
    '$scope',
    '$timeout',
    'CartServ',
    'OrderServ',
    'GrowlServ',
    'ConfirmationServ',
    'SplashServ',
    'ShippingServ',
    'CrossSellServ',
    function($scope, $timeout, cartServ, orderServ, growl, confirmation, splash, shippingServ, crossSellServ) {

        $scope.order = orderServ.fetchOrder() || {email: "@"};
        $scope.cart = cartServ.getCart();

        getCrossSell(cartServ.getCart().map(x => x.slug), null);

        $scope.$on('cartUpdated', function() {
            $scope.cart = cartServ.getCart();
            refreshOptions();
        });

        $scope.cartPrice = function() {
            return cartServ.getCartPrice();
        };

        $scope.vatCart = function() {
            return cartServ.getVatCart();
        };

        $scope.vatTotal = function() {
            return $scope.vatCart() + shippingServ.getShippingVat($scope.order.shipping, $scope.order.payment, cartServ.hasFreeShipping());
        };

        $scope.shippingPrice = function() {
            return shippingServ.getShippingPrice($scope.order.shipping, $scope.order.payment, cartServ.hasFreeShipping());
        };

        $scope.updateCart = function() {
            cartServ.setCart($scope.cart);
        };

        $scope.updateOrder = function() {
            orderServ.updateOrder($scope.order);
            $scope.order = orderServ.fetchOrder();
            refreshOptions();
        };

        $scope.proceedTo = section => {
            refreshOptions();
            switch (section) {
                case "cart":
                    $scope.st.go("order.cart");
                    break;
                case "shipping":
                    if (_size($scope.cart) > 0) {
                        $scope.st.go("order.shipping");
                        break;
                    } else {
                        growl.warning("Váš košík je prázdný. Zatím proto nelze provést objednávku.");
                    }
                    break;
                case "customer":
                    if ($scope.order.payment && $scope.order.shipping) {
                        if ($scope.order.shipping === 'zasilkovna') {

                            $scope.order.zasilkovnaParcelShopId = $("#zasilkovna-branchid").val();
                            $scope.order.zasilkovnaParcelShopName = $("#zasilkovna-branchname").text();

                            if (!$scope.order.zasilkovnaParcelShopId) {
                                growl.error("Pro pokračování je nutné vybrat pobočku Zásilkovny.");
                                $scope.scrollTo("order-shipping");
                                splash.hide();
                                $scope.st.go("order.shipping");
                            } else {
                                $scope.st.go("order.customer");
                            }
                        } else {
                            $scope.st.go("order.customer");
                        }
                    } else {
                        if (_size($scope.cart <= 0)) {
                            growl.warning("Váš košík je prázdný.");
                        } else {
                            growl.warning("Je třeba zadat způsob dopravy a platby");
                            $scope.st.go("order.shipping");
                        }
                    }
                    break;
                default:
                    break;
            }
        };

        $scope.sendOrder = function() {
            splash.show();
            orderServ.sendOrder($scope.order)
                .then(function(data) {
                    splash.hide();
                    // Redirect to gopay
                    if (data.data.gatewayUrl) {
                        window.location.href = data.data.gatewayUrl;
                        return;
                    }
                    growl.info("Vaše objednávka byla úspěšně odeslána!");
                    $scope.st.go("thanks", {'orderId': data.data.orderUUID});
                })
                .catch(function(data) {
                    splash.hide();
                    if (data.status === 500) {
                        tracker.exception("Order send failed: 500", true);
                    } else {
                        tracker.exception("Order send failed: "+data.status, false);
                    }
                    growl.error("Objednávku se nezdařilo odeslat. Prosím překontrolujte zadané údaje a případně nás kontaktujte.");
                });
        };

        // SHIPPING SECTION

        let payments = [], shippings = [];

        // Initial shipping and payment load
        shippingServ.getShippingMethods()
            .then(data => {
                shippings = data;
                // ZASILKOVNA PROPS START
                window.packetaSelectorOpen = '.packeta-selector-open';
                window.packetaSelectorBranchName = '.packeta-selector-branch-name';
                window.packetaSelectorBranchId = '.packeta-selector-branch-id';
                window.packetaCountry = 'cz';
                window.packetaWidgetLanguage = 'cs';
                window.packetaPrimaryButtonColor = '#39b54a';
                window.packetaBackgroundColor = '#ffffff';
                window.packetaFontColor = '#555555';
                window.packetaFontFamily = 'Arial';
                // ZASILKOVNA PROPS END
                const freeShipping = shippings.find(n => n.slug === 'free_shipping');
                if (freeShipping) {
                    $scope.freeShippingThreshold = freeShipping.minimum;
                }
                $scope.shippings = data; // Used for searching for name by slug in summary
                $scope.order.shipping = $scope.shippings[0].slug; // SEE Now only one shipping is set
                orderServ.updateOrder($scope.order);
                refreshOptions();
            })
            .catch(function() {
                growl.error("Nepodařilo se načíst způsoby dopravy. Vyzkoušejte prosím obnovit stránku. Pokud problém přetrvá, prosíme kontaktujte nás.", "Došlo k neočekávané chybě");
                tracker.exception("Shipping load failed", true);
            });

        shippingServ.getPaymentMethods()
            .then(function(data) {
                payments = data;
                $scope.payments = data; // Used for searching for name by slug in summary
                refreshOptions();
            })
            .catch(function() {
                growl.error("Nepodařilo se načíst způsoby platby. Vyzkoušejte prosím obnovit stránku. Pokud problém přetrvá, prosíme kontaktujte nás.", "Došlo k neočekávané chybě");
                tracker.exception("Payment load failed", true);
            });

        // GENERAL ORDER THINGS

        function refreshOptions() {
            $scope.possibleShippings = shippingServ.getPossibleShippings(cartServ.getCartWeight(), cartServ.getCartSize(), cartServ.getCartPrice(), cartServ.hasFreeShipping(), null);
            $scope.possiblePayments = shippingServ.getPossiblePayments(orderServ.fetchOrder(),cartServ.getCartPrice());
            // In case of changing the shipping method with already selected payment, I need to check
            // whether the selected payment is allowed for the newly selected shipping. If not, 'deselect' the payment.
            if ($scope.possibleShippings.length && !_some($scope.possibleShippings, {slug: $scope.order.shipping})) {
                $scope.order.shipping = null;
            }
            if ($scope.possiblePayments.length && !_some($scope.possiblePayments, {slug: $scope.order.payment})) {
                $scope.order.payment = null;
            }
        }

        let searchables = ["shippings", "payments"];
        $scope.getName = function(prop, val) {
            if (_some(searchables, function(n){return n === prop;})) {
                let foundProp = _find($scope[prop], {slug: val});
                return foundProp ? foundProp.name : "";
            } else {
                throw new Error("Accessing "+prop+" on scope not allowed");
            }
        };

        function getCrossSell(cartProductsSlugs, category) {
            crossSellServ.getCrossSell(cartProductsSlugs, null)
                .then(function(data) {
                    $scope.otherProducts = data;
                    tracker.productListView(data, "Cart crosssell");
                    let swiperOptions = {
                        slidesPerView: 3,
                        // Navigation arrows
                        navigation: {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev',
                        },
                        breakpoints: {
                            0: {
                              slidesPerView: 1
                            },
                            550: {
                              slidesPerView: 2
                            },
                            1150: {
                              slidesPerView: 3
                            }
                        }
                    }

                    $timeout(function() {
                        let swiper = new Swiper('#featured-slider', swiperOptions);
                    },500);
                });
        }

        // TODO Link to form object, not this shit
        $scope.readyForSend = function() {
            let o = $scope.order;
            let b = o.billingAddress;
            if (o && b) {
                return $scope.cart.length && o.shipping && o.payment && b.firstName && b.lastName && b.email && b.phoneNumber && b.street && b.city && b.postalCode;
            } else {
                return false;
            }
        };
    }]);
