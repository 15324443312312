import _replace from 'lodash/replace';

window.app.controller('ProductSearchCtrl',
    ['$scope', '$http',
        function($scope, $http) {

            $scope.emptySearch = false;

            $scope.searchQuery = $scope.st.params.phrase;

            if (!$scope.st.params.phrase){
                $scope.emptySearch = true;
            } else {
                search();
            }

            function search() {
                $scope.resultsLoaded = false;
                $http.get("/api/v1/eshop/product/fulltext/product?q="+prepareSearchPhrase($scope.searchQuery))
                    .then(function(data) {
                        const products = data.data.payload;
                        $scope.foundProds = products.map(n => n.item);
                        $scope.resultsLoaded = true;
                        $scope.resultLength = $scope.foundProds.length;
                    });
            }

            function prepareSearchPhrase(phrase) {
                phrase = _replace(phrase, new RegExp(/[-/:.\+]/, "g"), " ");
                phrase = _replace(phrase, new RegExp(/\s+/, "g"), " ");
                return encodeURIComponent(phrase);
            }

        }
        ]);
