import $ from 'jquery';
require('../_base/product-serv');
require('../_base/category-serv');
require('../_base/filter-serv');

window.app.controller('ProductsResultCtrl',
    ['$scope', 'PageMetaServ', 'ProductServ', 'FilterServ', '$filter', '$sce', '$http', '$window', '$timeout', 'CategoryServ',
        function($scope, meta, prodServ, filterServ, $filter, $sce, $http, $window, $timeout, categoryServ) {
            $scope.currentPage = Number($scope.st.params.strana) || 1;
            $scope.pageSize = Number($scope.st.params.polozky) || 24;

            if ($scope.st.params.category) {
                $scope.filterLoading = true;
                categoryServ.getCategory($scope.st.params.category)
                    .then(data => {
                        $scope.activeCategory = data;
                        $scope.activeCategory.description = $sce.trustAsHtml($scope.activeCategory.description);
                        $scope.setFilterParams($scope.activeCategory.slug);
                        meta({
                            title: $scope.activeCategory.metaTitle || $scope.activeCategory.name,
                            description: $scope.activeCategory.metaDescription,
                            robots: $scope.st.params.strana ? "noindex" : "index,follow"
                        });
                    });
            } else {
                meta({
                    title: "Cristine stylové květiny do kanceláří a interiérů",
                    description: "Cristine online květinářství nabízí originální stylové kytice, interiérové rostliny, květinové dekorace a japonské kokedamy",
                    robots: "index,follow"
                });
            }

            let filtCrt = {
                category: $scope.st.params.category,
                brand: $scope.st.params.znacka,
                page: $scope.currentPage,
                limit: $scope.pageSize,
                stock: $scope.st.params.skladem,
                // search:     $scope.st.params.hledani ? decodeURIComponent($scope.st.params.hledani) : '',
                priceFrom: $scope.st.params.cenaOd,
                priceTo: $scope.st.params.cenaDo,
                sortBy: $scope.st.params.razeni,
                parameters: $scope.st.params.parametry,
            };

            filterServ.setCriteria(filtCrt);
            filterServ.getActiveCriteria();

            // GET PRODUCTS BY PROVIDED CRITERIA
            $scope.products = [];
            filterServ.getFilteredProducts()
                .then(data => {
                    $scope.productsLoaded = true;
                    $scope.products = data.payload;
                    $scope.resultLength = data.totalItems;
                    $scope.lastPage = Math.ceil(data.totalItems / $scope.pageSize);
                    if ($(window).scrollTop() > 400) {
                        $scope.hasSeenTop = false;
                    }
                });

            $(window).on("scroll", function() {
                if ($(window).scrollTop() < 400) $timeout(function() {$scope.hasSeenTop = true;});
            });
        }
        ]);
