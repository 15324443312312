import * as tracker from '../utils/commerce';
require('../_base/page-meta-serv');
require('../_base/growl-serv');
require('../_base/splash-serv');
import {Swiper, EffectCoverflow, Navigation, Pagination, Autoplay, Thumbs } from 'swiper';
Swiper.use([EffectCoverflow, Navigation, Pagination, Autoplay, Thumbs ]);

window.app.controller('GreenWallsCtrl',
    ['$scope', 'PageMetaServ', '$http', 'GrowlServ', 'SplashServ', '$timeout',
        function($scope, meta, $http, growl, splash, $timeout) {

            meta({
                title: "Mechové stěny a vertikální zahrady | Cristine Stylové květiny",
                description: "Mechové stěny a vertikální zahrady jsou moderní dekorace na zdi, které krásně zazelení jakýkoliv prostor. Je možné je zrealizovat téměr kdekoliv oživit tak váš interiér. Skvěle se hodí do kanceláří, recepcí stejně jako k vám domů.",
            });

            var swiperOptions = {
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: 1.6,
                spaceBetween: 140,
                // Loop or not to loop?
                loop: true,
                loopedSlides: 3,
                autoplay: {
                    delay: 6000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 80,
                    modifier: 3,
                    slideShadows: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    750: {
                        coverflowEffect: {
                            stretch: 0
                        }
                    },
                    550: {
                        coverflowEffect: {
                            stretch: -10
                        }
                    },
                    400: {
                        slidesPerView: 1.1
                    }
                }
            }

            $timeout(function() {
                var swiperWalls = new Swiper('.swiper-walls', swiperOptions);

                var swiper = new Swiper(".swiper-moss-thumbs", {
                    spaceBetween: 10,
                    slidesPerView: 4,
                    freeMode: true,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                  });
                  var swiper2 = new Swiper(".swiper-moss", {
                    thumbs: {
                      swiper: swiper,
                    },
                  });
            });

            const emptyForm = {
                email: '',
                phoneNumber: '',
                message: '',
                // TODO PKO hack, remove once the contact is properly implemented on BE
                name: 'zelene-steny kontakt'
            };
            $scope.form = Object.assign({}, emptyForm);

            $scope.sendDemand = function() {
                const postConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                splash.show();
                $http.post('/api/v1/contact/', $scope.form, postConfig)
                    .then(function() {
                        splash.hide();
                        $("form")[0].reset();
                        growl.info('Zpráva byla odeslána', 5000);
                        $scope.form = Object.assign({}, emptyForm);
                        $scope.formErrors = {};
                        tracker.event("conversion", {category: 'contact', value: 2000, label: 'contact-greenWalls'});
                        $scope.demand.$setPristine();
                    })
                    .catch(function(data) {
                        splash.hide();
                        if (data.status === 400) {
                            tracker.exception("greenWalls-form-error", false);
                            growl.error('Zadali jste nesprávné údaje do formuláře.');
                            $scope.formErrors = data.data.errors;
                        } else {
                            tracker.exception("greenWalls-form-error", true);
                            growl.error('Při odeslání došlo k chybě. Kontaktujte nás prosím telefonicky.');
                        }
                    });
            };

        }
        ]);
