import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';

window.app.service('ProductServ', ['$filter', '$http', '$q',
    function($filter, $http, $q) {

        /**
         * Format product properties for future use
         * @param  {Array}    products
         * @return {Array}
         */
        this.formatProducts = function(products) {
            products.forEach(n => {
                if (n.discounted) {
                    n.nicePrice = Number(n.discounted);
                } else {
                    n.nicePrice = Number(n.price.split("od ").join("").split(",").join("."));
                }
            });
            return products;
        };

        /**
         * Used to filter products by given criteria
         * @param  {Array}    products
         * @param  {Object}   crt
         * @return {Array}
         */
        // this.filterProducts = function(products, crt) {
        //     let filt = _cloneDeep(products);
        //
        //     if (crt.category !== 'vse' && crt.category !== 'komplet') {
        //         filt = filt.filter(n => {
        //             return n.categorySlug === crt.category;
        //         });
        //     }
        //
        //     if (crt.brand) {
        //         _remove(filt, function(n) {
        //             return n.brandSlug !== crt.brand;
        //         });
        //     }
        //
        //     if (crt.priceFrom) {
        //         _remove(filt, function(n) {
        //             return n.nicePrice < Number(crt.priceFrom);
        //         });
        //     }
        //     if (crt.priceTo) {
        //         _remove(filt, function(n) {
        //             return n.nicePrice > Number(crt.priceTo);
        //         });
        //     }
        //
        //     filt = $filter('filter')(filt, crt.searchFor);
        //     filt = $filter('filter')(filt, crt.quickSearch);
        //
        //     return filt;
        // };

        /**
         * Sort products by one of properties
         * @param  {Array}    products
         * @param  {Object}   par
         * @return {Array}
         */
        this.sortProducts = function(products, par) {
            switch (par.type) {
                case "nejlevnejsi":
                    products = _sortBy(products, function(n) { return n.nicePrice; });
                    break;
                case "nejdrazsi":
                    products = _reverse(_cloneDeep(_sortBy(products, function(n) { return n.nicePrice; })));
                    break;
                case "skladem":
                    products = _sortBy(products, function(n) { return n.stock > 0 ? 0 : 1; });
                    break;
                default:
                    break;
            }
            if (par.dir === "sestupne") {
                products = _reverse(products);
            }
            return products;
        };

        this.getBestSellingProducts = limit => {
            const deferred = $q.defer();

            $http.get('/api/v1/eshop/product/best-selling-products?limit='+(limit || 10), null, {cache: true})
                .then(function(data) {
                    deferred.resolve(data.data);
                });

            return deferred.promise;
        };

        this.getNewestProducts = limit => {
            const deferred = $q.defer();

            $http.get('/api/v1/eshop/product/newest-products?limit='+(limit || 10), null, {cache: true})
                .then(function(data) {
                    deferred.resolve(data.data);
                });

            return deferred.promise;
        };

        this.getDiscountedProducts = limit => {
            const deferred = $q.defer();

            $http.get('/api/v1/eshop/product/discounted-products?limit='+(limit || 10), null, {cache: true})
                .then(function(data) {
                    deferred.resolve(data.data);
                });

            return deferred.promise;
        };

    }]);
