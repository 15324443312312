import * as tracker from '../utils/commerce';
import $ from 'jquery';

window.app.controller('ContactCtrl',
    ['$scope', 'PageMetaServ', '$http', 'GrowlServ', 'SplashServ',
        function($scope, meta, $http, growl, splash) {

            meta({
                title: "Kontakt Cristine Stylové květiny",
                description: "",
            });

            $scope.form = {};

            $scope.sendForm = function() {
                const postConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                splash.show();
                $http.post('/api/v1/contact/', $scope.form, postConfig)
                    .then(function() {
                        splash.hide();
                        $("form")[0].reset();
                        growl.info('Zpráva byla odeslána', 5000);
                        $scope.form = {};
                        $scope.formErrors = {};
                        tracker.event("conversion", {category: 'contact', value: 500, label: 'contact-main'});
                        $scope.contact.$setPristine();
                    })
                    .catch(function(data) {
                        splash.hide();
                        if (data.status === 400) {
                            tracker.exception("contact-form-error", false);
                            growl.error('Zadali jste nesprávné údaje do formuláře.');
                            $scope.formErrors = data.data.errors;
                        } else {
                            tracker.exception("contact-form-error", true);
                            growl.error('Při odeslání došlo k chybě. Kontaktujte nás prosím telefonicky.');
                        }

                    });

            };

        }
]);
