import * as tracker from '../utils/commerce';
import {Swiper, EffectCoverflow, Navigation, Pagination } from 'swiper';
Swiper.use([EffectCoverflow, Navigation, Pagination ]);

window.app.controller('WeddingCtrl',
    ['$scope', 'PageMetaServ', '$http', 'GrowlServ', 'SplashServ', '$timeout',
        function($scope, meta, $http, growl, splash, $timeout) {

            meta({
                title: "Svatební kytice pro nevěsty, družičky, dekorace na svatby | Cristine České Budějovice",
                description: "",
            });

            var swiperOptions = {
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: 1.6,
                spaceBetween: 140,
                // Loop or not to loop?
                loop: true,
                loopedSlides: 3,
                autoplay: {
                    delay: 6000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 80,
                    modifier: 3,
                    slideShadows: false
                },
                breakpoints: {
                    750: {
                        coverflowEffect: {
                            stretch: 0
                        }
                    },
                    550: {
                        coverflowEffect: {
                            stretch: -10
                        }
                    },
                    400: {
                        slidesPerView: 1.1
                    }
                }
            }

            $timeout(function() {
                var swiper = new Swiper('.swiper-container', swiperOptions);
            });

            const emptyForm = {};
            $scope.form = {};

            /**
             * @param type Supported types: 'full', 'partial'. 'full' is fallback.
             */
            $scope.sendDemand = function(type) {
                if (!type) {
                    type = 'full'
                }

                const postConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: {
                        type: type
                    }
                };
                splash.show();
                $http.post('/api/v1/contact/wedding', $scope.form, postConfig)
                    .then(function() {
                        splash.hide();
                        $("form")[0].reset();
                        growl.info('Zpráva byla odeslána', 5000);
                        $scope.form = Object.assign({}, emptyForm);
                        $scope.formErrors = {};
                        tracker.event("conversion", {category: 'contact', value: 0, label: 'contact-wedding'});
                        $scope.demand.$setPristine();
                    })
                    .catch(function(data) {
                        splash.hide();
                        if (data.status === 400) {
                            tracker.exception("wedding-form-error", false);
                            growl.error('Zadali jste nesprávné údaje do formuláře.');
                            $scope.formErrors = data.data.errors;
                        } else {
                            tracker.exception("wedding-form-error", true);
                            growl.error('Při odeslání došlo k chybě. Kontaktujte nás prosím telefonicky.');
                        }
                    });
            };
        }]);
