const { default: Swiper } = require("swiper");

window.app.controller('HomeCtrl',
    ['$scope', 'PageMetaServ', '$http', 'SplashServ', 'GrowlServ', '$timeout',
        function($scope, meta, $http, splash, growl, $timeout) {
            meta({
                title: "Cristine stylové květiny do kanceláří a interiérů",
                description: "Cristine online květinářství nabízí originální stylové kytice, interiérové rostliny, květinové dekorace a japonské kokedamy",
                image: "https://www.greenjungle.cz/assets/fb-cover.jpg",
                url: "https://www.greenjungle.cz"
            });

            setTilesSize();

            $(window).resize(function() {
                setTilesSize();
            });

            function setTilesSize() {
                $(".tile").each(function() {
                    $(this).height($(this).width());
                });
            }

            $scope.contactInfo = {};

            $scope.contactMe = function() {
                const postConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: $scope.contactInfo
                };
                splash.show();
                $http.post('/api/v1/eshop/contactForm', false, postConfig)
                    .then(function() {
                        splash.hide();
                        growl.info('Zpráva byla odeslána', 5000);
                        $scope.contactInfo = {};
                        $scope.quickContact.$setPristine();
                    })
                    .catch(function() {
                        splash.hide();
                        growl.error('Při odeslání došlo k chybě. Může to být způsobeno neplatnými daty ve formuláři. Pokuste se data opravit a případně nás kontaktujte telefonicky.');
                    });

                tracker.event("conversion", {category: 'contact', value: 500, label: 'contact-me'});
            };

            $scope.microData = {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Cristine Stylové Květiny",
                "legalName" : "GREEN JUNGLE s.r.o.",
                "url": "https://www.greenjungle.cz/",
                // "logo": "https://www.greenjungle.cz/assets/logo.png",
                "founders": [
                    {
                        "@type": "Person",
                        "name": "Kristýna Králová"
                    }
                ],
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Vrbenská 511/25",
                    "addressLocality": "České Budějovice",
                    "postalCode": "37001",
                    "addressCountry": "CZE"
                },
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "customer service",
                    "telephone": "[+420 777 931 951]",
                    "email": "info@greenjungle.cz"
                },
                "sameAs": [
                    "https://www.facebook.com/stylovekvetiny.cz"
                ]
            };

            var swiperOptions = {
                loop: true,

                // Navigation arrows
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
            }

            $timeout(function() {
                var swiper = new Swiper('.swiper-container', swiperOptions);
            });

    }]);
