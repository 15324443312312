export default {
    "antracit":             "antracit",
    "bílá":                 "white",
    "espresso":             "espresso",
    "kardamon zelená":      "cardamon",
    "meruňková":            "apricot",
    "mátová":               "mint",
    "paví modrá":           "peacock",
    "skandinávská modrá":   "scandinavia",
    "stříbrná":             "silver",
    "taupe":                "taupe",
    "černá":                "black",
    "červená":              "scarlett",
    "cilindro bílá":        "cilindro-white",
    "cilindro černá":       "cilindro-black",
    "cilindro javor":       "cilindro-maple",
    "šedá kámen":           "stonegrey",
    "písková":              "sandbeige",
    "grafit":               "graphite",
    "bílo-zelená":          "white-green",
    "bílo-šedá":            "white-grey",
    "růžová":               "pink",
}
