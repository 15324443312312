import _forEach from "lodash/forEach";
import _isArray from "lodash/isArray";
import _assign from "lodash/assign";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _isUndefined from "lodash/isUndefined";
import $ from "jquery";
import _some from "lodash/some";
import _remove from "lodash/remove";
import _size from "lodash/size";

window.app.service('FilterServ', ['$http', '$q',
    function($http, $q) {

        let loadedParamsForCategory = null;

        this.getFilterData = categorySlug => {
            const deferred = $q.defer();
            if (categorySlug !== loadedParamsForCategory) {
                $http.get('/api/v1/eshop/product/parameters', {params: {category: categorySlug}})
                    .then(function(data) {
                        data.data.forEach(n => {
                            if (n.filterValues !== undefined && n.filterValues.length > 0) n.values = n.filterValues;
                        });
                        loadedParamsForCategory = categorySlug;
                        deferred.resolve(data.data);
                    });
            } else {
                deferred.reject();
            }
            return deferred.promise;
        };

        let criteria = null;

        this.setCriteria = inputCriteria => {
            criteria = {
                category:   inputCriteria.category,
                brand:      inputCriteria.znacka,
                page:       inputCriteria.page,
                limit:      inputCriteria.limit,
                stock:      inputCriteria.skladem,
                // search:     $scope.st.params.hledani ? decodeURIComponent($scope.st.params.hledani) : '',
                priceFrom:  inputCriteria.priceFrom,
                priceTo:    inputCriteria.priceTo,
                sortBy:     getSortParam(inputCriteria.sortBy),
            };

            if (inputCriteria.parameters) {
                _assign(criteria, JSON.parse(decodeURIComponent(escape(atob(inputCriteria.parameters)))));
            }

            // Delete undefined properties
            _forEach(criteria, function (val, key) {
                if (_isUndefined(val)) {
                    delete criteria[key];
                }
            });
        };

        this.toggleFilterParam = (parSlug, parValue) => {
            if (isParamActive(parSlug, parValue)) {
                _remove(criteria[parSlug], function(n) {return n === parValue;});
            } else {
                if (_size(criteria[parSlug]) === 0) {criteria[parSlug] = [];}
                criteria[parSlug].push(parValue);
            }
        };

        this.getEncodedCriteria = () => {
            return btoa(unescape(encodeURIComponent(JSON.stringify(criteria))));
        };

        this.getActiveCriteria = () => {
            const deferred = $q.defer();
            $http.get('/api/v1/eshop/product/parameters', {params: {category: criteria.category}})
                .then(parameters => {
                    const crtArr = [];
                    let waitingForBrand = false, allDone = false;
                    _forEach(criteria, function(val, key) {
                        const newPar = {slug: key, name: matchParam(key, parameters.data)};
                        // Only add parameters where name was found
                        if (newPar.name) {
                            // For brand, you have to find the brand's name by slug
                            if (key === 'brand') {
                                waitingForBrand = true;
                                getBrandBySlug(val) // val is the brand's slug
                                    .then(function(brand) {
                                        newPar.value = brand.name;
                                        crtArr.push(newPar);
                                        waitingForBrand = false;
                                        if (allDone) {
                                            deferred.resolve(crtArr);
                                        }
                                    })
                                    .catch(function() {
                                        waitingForBrand = false;
                                        if (allDone) {
                                            deferred.resolve(crtArr);
                                        }
                                    });
                                return;
                            }

                            // If there are more valued, create more tags for active filters
                            if (_isArray(val)) {
                                _forEach(val, function(n) {
                                    newPar.value = n;
                                    crtArr.push(_cloneDeep(newPar));
                                });
                            } else {
                                // Otherwise just add it as it is
                                newPar.value = val;
                                crtArr.push(newPar);
                            }
                        }
                    });
                    allDone = true;
                    if (!waitingForBrand) {
                        deferred.resolve(crtArr);
                    }
                });
                return deferred.promise;
        };

        this.getFilteredProducts = () => {
            const deferred = $q.defer();
            $http({
                method: 'GET',
                // XXX
                // This shit pisses me off but when the param object is passed as params with some of the values as array,
                // the result URL is like key=1&key=2. If you hack the fucking object and add "[]" to the property name, then
                // it is not key[]=1&key[]=2 as you would expect. no,no,noo. It escapes the goddamn square brackets and it's a shit again
                // I don't know how this will behave when passed stupid string values as they will be decoded before sending the request,
                // but have no idea how to solve it elsehow.
                url: '/api/v1/eshop/product/products?' + decodeURIComponent($.param(criteria)),
            })
                .then(data => {
                    if (data.data.totalItems > 0) {
                        deferred.resolve(data.data);
                    } else {
                        $http({
                            method: 'GET',
                            url: '/api/v1/eshop/product/products?variantsOnly=1&' + decodeURIComponent($.param(criteria)),
                        }).then(function (data) {
                            deferred.resolve(data.data);
                        });
                    }
                });
            return deferred.promise;
        };

        function isParamActive(parSlug, value) {
            return _some(criteria[parSlug], n => n === value);
        }
        this.isParamActive = isParamActive;

        function matchParam(parSlug, parameters) {
            const match = _find(parameters, {slug: parSlug});
            if (match) return match.name;
            const dictionary = {
                brand:      "Značka",
                stock:      "Pouze skladem",
                priceFrom:  "Cena od",
                priceTo:    "Cena do",
            };
            return dictionary[parSlug];
        }

        function getBrandBySlug(brandSlug) {
            const deferred = $q.defer();
            $http.get('/api/v1/eshop/product/brands', null, {cache: true})
                .then(function(data) {
                    let matchBrand = _find(data.data, {slug: brandSlug});
                    if (matchBrand) {
                        deferred.resolve(matchBrand);
                    } else {
                        deferred.reject();
                    }
                });
            return deferred.promise;
        }

        function getSortParam(param) {
            const dict = {
                nejnovejsi: 'newest',
                nejdrazsi: 'price-desc',
                nejlevnejsi: 'price-asc',
            };
            return dict[param];
        }

    }]);
