import thankConversion from "../utils/thank-conversion";

window.app.controller('ThanksCtrl', [
    '$scope',
    'CartServ',
    'OrderServ',
    'PageMetaServ',
    'ShippingServ',
    function($scope, cartServ, orderServ, meta, shippingServ) {

        meta({
            title: "Děkujeme za Vaši objednávku | Cristine Stylové květiny",
            description: '',
            robots: "nofollow,noindex"
        });

        let orderObj = orderServ.fetchOrder();

        thankConversion({
            cartPrice:      cartServ.getCartPrice(),
            cart:           cartServ.getCart(),
            orderId:        $scope.st.params.orderId,
            vat:            cartServ.getVatCart(),
            shippingPrice:  shippingServ.getShippingPrice(orderObj.shipping, orderObj.payment),
        });

        cartServ.clearCart();
        orderServ.updateOrder({});
        $scope.cart = cartServ.getCart();

    }]);
