window.app.controller('ConditionsCtrl', [
'$scope',
'PageMetaServ',
'$http',
'$sce',
function($scope, meta, $http, $sce) {
    meta({
        title: "Obchodní podmínky | Cristine Stylové květiny",
        description: '',
    });

    $scope.loading = true;

    $http.get('/api/v1/eshop/core/agreement/obchodni-podminky', {cache: true})
    .then(function(data) {
        let conditions = data.data;
        conditions.content = $sce.trustAsHtml(conditions.content);
        $scope.conditions = conditions;
        $scope.loading = false;
    });


}]);
