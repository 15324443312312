window.app.controller('AboutCtrl',  ['$scope', 'PageMetaServ', '$http',
    function($scope, meta, $http) {

        meta({
            title: "O nás | Cristine Stylové květiny",
            description: "",
        });

        $http.get('/api/v1/eshop/product/categories', null, {cache: true})
            .then(function(data) {
                $scope.categories = data.data;
            });
    }
]);
