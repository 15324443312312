import $ from 'jquery';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import _some from 'lodash/some';
import _size from 'lodash/size';
import _remove from 'lodash/remove';
import noUiSlider from 'nouislider';
import colors from '../utils/colors';

require('../_base/category-serv');

window.app.controller('ProductsCategoryCtrl',
    ['$scope', '$http', '$sce', '$timeout', 'CategoryServ',
        function($scope, $http, $sce, $timeout, categoryServ) {

            if ($scope.st.params.category) {
                categoryServ.getCategory($scope.st.params.category)
                    .then(data => {
                        const activeCategory = data;
                        activeCategory.description = $sce.trustAsHtml(activeCategory.description);
                        categoryServ.getCategories(null, activeCategory.slug)
                            .then(data => {
                                $scope.subcategories = data;
                            });
                        $scope.activeCategory = activeCategory;
                        $timeout(function(){$scope.descriptionHeight = angular.element(".category-description")[0].offsetHeight;})
                    });
            }

            $scope.fullDescription = false;
            $scope.showFullDescription = function() {
                $scope.fullDescription = true;
            }


            // TODO
            // $scope.search = function(reset) {
            //     if (reset) {
            //         $scope.st.go('productsFilter.filtering', {'hledani': ''});
            //         $scope.searchPhrase = "";
            //         $timeout(function() {
            //             $scope.searchFor = "";
            //         }, 200);
            //         return;
            //     }

            //     window.fbq('track', 'Search', {
            //         search_string: $scope.searchPhrase,
            //         content_category: $scope.activeCategory || "produkty",
            //     });

            //     $scope.st.go('productsFilter.filtering', {'category': '', 'strana': '', 'znacka': '', 'cenaOd': '', 'cenaDo': '', 'razeni': '', 'smer': '', 'hledani': encodeURIComponent($scope.searchPhrase)});
            //     $timeout(function() {
            //         $scope.searchFor = $scope.searchPhrase;
            //     }, 200);
            // };

            // TODO
            // $scope.toggleProductCategories = function() {
            //     $('.mobilesidebar-category').toggleClass('toggled');
            // };
            //
            // $scope.toggleProductFilters = function() {
            //     $('.mobilesidebar-filter').toggleClass('toggled');
            // };
            //
            // $scope.toggleFilter = function($event, filterid) {
            //     $('.filter-button').removeClass('active');
            //     $($event.currentTarget).addClass('active');
            //     $('.fwrap').removeClass('active');
            //     $('#filter-'+filterid).addClass('active');
            // };

        }
        ]);
