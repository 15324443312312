require('../less/style.less')
// For email purposes.
require('../images/fb-logo.png')
require('../images/ig-logo.png')

import angular from "angular";
require("angular-animate")
require("angular-ui-router")
require("angular-i18n/angular-locale_cs")

window.app = angular.module("cristine", ['ui.router', 'ngAnimate']);

require("./_base/route-conf")

require("./directives/any-loader")
require("./directives/any-tooltip")
require("./directives/any-growl")
require("./directives/any-cartpopup")
require("./directives/any-header")
require("./directives/any-footer")
require("./directives/product-card")

require("./controllers/root")
require("./controllers/head")


angular.bootstrap(document, ['cristine']);

import {giveConsent, getCheckedConsent} from "./utils/consents";

window.AC = {
    giveConsent: giveConsent,
    getCheckedConsent: getCheckedConsent,
}
