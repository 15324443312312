window.app.controller('PaymentResponseCtrl', [
    '$scope',
    'PageMetaServ',
    '$http',
    function($scope, meta, $http) {

        meta({
            title: "Ověřujeme stav platby | Cristine Stylové květiny",
            description: '',
            robots: "nofollow,noindex"
        });

        $http.get('/payment/methods/gopay/gatewayReturn', {id: $scope.st.params.id})
            .then(function(data) {
                if (data.data.status === 'gopay_created' || data.data.status === 'gopay_payment_method_chosen') {
                    $scope.st.go('paymentCreated');
                } else {
                    $scope.st.go("thanks");
                }
            }, function(data) {
                $scope.st.go("paymentFailed", {orderId: data.data.orderId});
            });

    }]);
