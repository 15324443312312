window.app.service('ConfirmationServ', [
        '$timeout',
        function($timeout) {

        const model = {
            clear: function() {
                reset();
            }
        };

        reset();

        /**
         * @param  {String}   text
         * @param  {Function} confirmCallback
         * @param  {Function} (optional) rejectCallback
         */
        this.request = function(text, confirmCallback, rejectCallback) {
            model.text = text;
            model.classes = ['active'];
            model.accept = confirmCallback;
            if (rejectCallback) {
                model.reject = rejectCallback;
            }
        };


        this.getModel = function() {
            return model;
        };

        this.clear = function() {
            reset();
        };

        function reset() {
            model.text = '';
            model.classes = [];
            model.accept = null;
            model.reject = function() {
                model.clear();
            };
        }
    }]);
