/**
 * @version  2.1-SPA
 * Fingerprintjs updated
 */
import loadAnalytics from './anylytics';
import Fingerprint2 from 'fingerprintjs2';
import $ from 'jquery';
import ACconfig from './config';

// Anyshop consent frontend manager v2.0
const defaultLevel = "2"; // Default level of scripts to load without consent
const denyUrl = "/bez-remarketingu"; // Url to be loaded

/**
 * Checks localstoraged consent. Consent is always storaged to localstorage
 * so it decides what to load/track on each page
 * @param  {Function}     consentCb
 */
export function checkLocalConsent(consentCb) {
    let consent = localStorage.getItem("anyconsent");
    if (consent) {
        let parsed = JSON.parse(consent);
        if (Number(parsed.lvl) >= 3) {
            hideDisclaimer();
        }
        loadAnalytics(parsed.lvl, consentCb);
    } else {
        // If no consent is storaged, load default level shit.
        // This doesn't mean the user gives consent so no consent is stored or sent
        loadAnalytics(defaultLevel);
    }
}

/**
 * Giving consent means executing an action which approves personal data saving.
 * Only intentional action like clicking the button in disclaimer or sending an order gives consent.
 * @param  {Number}   lvl    the higher the level, the more scripts are loaded and traces are being sent
 * @param  {String}   source to map how the consent has been obtained, we collect the source such as contact, order or disclaimer
 */
export function giveConsent(lvl, source) {
    let currentLvl = 0; // Current level is used to compare new level with the former.
    if (localStorage.getItem("anyconsent")) {
        currentLvl = Number(JSON.parse(localStorage.getItem("anyconsent")).lvl);
    } else {
        // If no consent was localstoraged, it means that default analytics level was loaded
        currentLvl = defaultLevel;
    }
    // Any consent is localstoraged (Yes, it can be lower than the current one)
    localStorage.setItem("anyconsent", JSON.stringify({savedAt: new Date().getTime(), lvl: lvl}));
    sendConsent(lvl, source);

    // Load the appropriate analytics
    checkLocalConsent();
}

/**
 * At the moment not used
 */
export function denyConsent() {
    stopGoogle();
    localStorage.clear(); // Just for sure
    // This should delete all cookies but messed up csrf tokens when implemented.
    // However cookie management can be made via browser settings.
    //var cookies = document.cookie.split(";");
    //for (var i = 0; i < cookies.length; i++) {
    //    var cookie = cookies[i];
    //    var eqPos = cookie.indexOf("=");
    //    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    //}
    //The only thing left in localStorag will be the denial
    localStorage.setItem("anyconsent", JSON.stringify({savedAt: new Date().getTime(), lvl: "0"}));
    window.location.href = denyUrl;
}

/**
 * Sets a flag that should stop google tracking globally. However, already sentdata to Google won't be deleted this way
 */
function stopGoogle() {
    window['ga-disable-'+ACconfig.ga] = true;
}

/**
 * Will return a consent level based on a setting. At the time of implementation only a little is known about how the setting will look like.
 * There may be a complete form controlling which data can and cannot be send.
 * @return {String} String with a number represenation of the consented level
 */
export function getCheckedConsent() {
    let consentLvl = "1";
    if ($("#statistics-consent").is(':checked')) consentLvl = "2";
    if ($("#marketing-consent").is(':checked')) consentLvl = "3";
    return consentLvl;
}

/**
 * Handle change of disclaimer's checkbox consents.
 * @param  {String} box checkbox name
 * @param  {Boolean} val
 */
export function changeConsent(box, val) {
    switch (box) {
        case "statistics":
            if (!val && $("#marketing-consent").is(':checked')) $("#marketing-consent").prop('checked', false);
            break;
        case "marketing":
            if (val && !$("#statistics-consent").is(':checked')) $("#statistics-consent").prop('checked', true);
            break;
        default:
            break;
    }
}

export function hideDisclaimer() {
    $('#cookies').animate({'height': 0});
}

/** Sends consent to backend. Used by giveConsent function.
    This one only handles the sending part and storing an identity
    to be able to identify that this device has given the consent */
function sendConsent(consentLvl, source) {
    // Remove possible outdated prints
    if (localStorage.getItem("anyid")) {
        let saved = localStorage.getItem("anyid");
        if (new Date().getTime() - saved.savedAt > 86400000) {
            localStorage.removeItem('anyid');
        }
    }
    // If no identity is stored, introduce
    if (!localStorage.getItem("anyid")) {
        Fingerprint2.get({
            excludes: {
                canvas: true,
                webgl: true,
            },
        }, function(components) {
            let values = components.map(component => component.value);
            let murmur = Fingerprint2.x64hash128(values.join(''), 31);
            let deviceInfo = {};
            localStorage.setItem("anyid", JSON.stringify({id: murmur, savedAt: new Date().getTime()}));
            let consent = {
                deviceId: murmur,
                deviceInfo: deviceInfo,
                consentLevel: consentLvl,
                source: source
            };
            const query = Object.keys(consent)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(consent[k]))
                .join('&');
            $.ajax({
                method: 'POST',
                url: '/api/v1/consent?' + query,
            });
        });
    }
}
