/**
 * @version  1.0-SPA
 */
/**
 * Adds script tag to DOM and set it's src
 * @param   {String}       url         URL of the script to be loaded
 * @param   {Function}     cb
 * @param   {Boolean}      reloadable  if true, script tag is put in reloadable element
 *                                  which is emptied with each statechange
 */
export function loadScript(url, cb, reloadable){
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState){  //IE
        script.onreadystatechange = function(){
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                if (cb) cb();
            }
        };
    } else {  //Others
        script.onload = function(){
            if (cb) cb();
        };
    }

    script.src = url;

    // In SPA, a part of scripts is being reloaded with each statechange using a reloadables container.
    // This container (div) is emptied with each statechange
    if (reloadable) {
        document.getElementById("reloadables").appendChild(script);
    } else {
        document.getElementsByTagName("head")[0].appendChild(script);
    }
}

export function loadImage(url, cb) {
    let image = document.createElement("img");
    if (image.readyState){  //IE
        image.onreadystatechange = function(){
            if (image.readyState === "loaded" || image.readyState === "complete") {
                image.onreadystatechange = null;
                if (cb) cb();
            }
        };
    } else {  //Others
        image.onload = function(){
            if (cb) cb();
        };
    }
    image.src = url;
    document.getElementsByTagName("body")[0].appendChild(image);
}

export function loadIframe(url) {
    let iframe = document.createElement('iframe');
    iframe.src = url;
    document.body.appendChild(iframe);
}
