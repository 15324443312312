/**
 * Service for saving and retrieving order from localstorage and sending it to backend
 * @version 3.3
 */
import _find from 'lodash/find';
import _size from 'lodash/size';
import _forEach from 'lodash/forEach';
import _some from 'lodash/some';
import _remove from 'lodash/remove';
import _isString from 'lodash/isString';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _groupBy from 'lodash/groupBy';
import _cloneDeep from 'lodash/cloneDeep';

window.app.service('ShippingServ', ['$http', '$q', function($http, $q) {

    let paymentMethods = [];
    let shippingMethods = [];

    this.getShippingMethods = function() {
        let deferred = $q.defer();

        $http.get("/api/v1/eshop/delivery/methods", null, { cache: true })
            .then(function(data) {
                shippingMethods = data.data;
                deferred.resolve(shippingMethods);
            })
            .catch(function() {
                deferred.reject();
            });

        return deferred.promise;
    };
    this.getPaymentMethods = function() {
        let deferred = $q.defer();

        $http.get("/api/v1/eshop/payment/methods", null, { cache: true })
            .then(function(data) {
                paymentMethods = data.data;
                deferred.resolve(paymentMethods);
            })
            .catch(function() {
                deferred.reject();
            });

        return deferred.promise;
    };

    this.getShippingPrice = function(shippingSlug, paymentSlug, freeShipping) {
        if (shippingSlug && paymentSlug && shippingMethods.length > 0 && paymentMethods.length > 0) {
            let shippingPrice = 0;
            if (!freeShipping) shippingPrice = _find(shippingMethods, { slug: shippingSlug }).price;
            let paymentPrice = _find(paymentMethods, { slug: paymentSlug }).price;
            return shippingPrice + paymentPrice;
        } else {
            return 0;
        }
    };

    this.getShippingVat = function(shippingSlug, paymentSlug, freeShipping) {
        if (shippingSlug && paymentSlug && shippingMethods.length > 0 && paymentMethods.length > 0) {
            let ship = _find(shippingMethods, { slug: shippingSlug });
            let paym = _find(paymentMethods, { slug: paymentSlug });
            let shippingVat = freeShipping ? 0 : (ship.price / ((100 + ship.vat_rate) / 100) * (ship.vat_rate / 100));
            let paymentVat = (paym.price / ((100 + paym.vat_rate) / 100) * (paym.vat_rate / 100));
            return shippingVat + paymentVat;
        } else {
            return 0;
        }
    };

    /**
     * Filters and transforms shippings to display only possible ones
     * @param  {Number}     weight
     * @param  {Number}     itemCount   Number of products in cart
     * @param  {Number}     cartPrice
     * @param  {Boolean}    freeShippingProduct
     * @param  {String}     country
     * @return {Array}
     */
    this.getPossibleShippings = function(weight, itemCount, cartPrice, freeShippingProduct, country) {

        if (!shippingMethods.length) return [];

        let possibleShippings = [];

        possibleShippings = this.getShippingsByWeight(shippingMethods, weight);

        // ----------- SHIPPING BY COUNTRY START ----------------
        if (_isString(country)) {
            possibleShippings = _filter(possibleShippings, function(o) {
                return _some(o.parameters.possible_countries, function(n) {
                    return n === country;
                });
            });
        }
        // ----------- SHIPPING BY COUNTRY END ----------------

        // ----------- SHIPPING BY CART PRICE START ----------------
        // Remove those shipping which minimum is above cartPrice and maximum is under cartPrice
        _remove(possibleShippings, function(n) {return n.minimum ? (cartPrice <= n.minimum) : false;});
        _remove(possibleShippings, function(n) {return n.maximum ? (cartPrice > n.maximum) : false;});
        // ----------- SHIPPING BY CART PRICE END ----------------

        if (freeShippingProduct) {
            possibleShippings.forEach(function(n) {
                n.price = 0;
            });
        }

        return possibleShippings;
    };

    this.getShippingsByWeight = function(shippings, weight) {
        // Expect to get slugs like weight_ppl_50. The aim is to create an array where each shipping partner gets one viable method (with the lowest possible weight limit)
        // group by every weightable carrier methods
        let groupedShippings = _groupBy(shippings, function(n) {
            let s = n.slug;
            if (s.indexOf('weight') > -1) {
                return s.split('weight_')[1].split('_')[0];
            } else {
                return "other";
            }
        });

        let possibleShippings = [];

        _forEach(groupedShippings, function(arr, shipper) {
            let matched = _find(_sortBy(arr, function(n) { return n.parameters.weight_limit; }), function(n) { return n.parameters.weight_limit >= weight; });
            if (matched) possibleShippings.push(matched);
        });

        if (_size(possibleShippings) == 0 && groupedShippings.individual) {
            possibleShippings = possibleShippings.concat(groupedShippings.individual);
        }

        possibleShippings = possibleShippings.concat(groupedShippings.other);

        return possibleShippings;
    };

    /**
     * Filters and transforms payments to display only possible ones
     * @return {Array}
     */
    this.getPossiblePayments = function(order,cartPrice) {

        if (!paymentMethods.length) return [];

        let shipping = null,
            country = null,
            possiblePayments = _cloneDeep(paymentMethods);

        if (order) {
            country = order.billingAddress ? order.billingAddress.country : null;
            shipping = order.shipping;
        }

        // ----------- PAYMENT BY SHIPPING START ----------------
        let selectedShipping = _find(shippingMethods, {slug: shipping});
        if (selectedShipping) {
            // Filter only those payments, which are in possible payments for selected shipping
            possiblePayments = _filter(paymentMethods, function(o) {
                return _some(selectedShipping.parameters.possible_payments, function(n) {
                    return n === o.slug;
                });
            });
        }
        // ----------- PAYMENT BY SHIPPING END ----------------

        // ----------- PAYMENT BY COUNTRY START ----------------
        if (_isString(country)) {
            possiblePayments = _filter(possiblePayments, function(o) {
                return _some(o.configuration.possible_countries, function(n) {
                    return n === country;
                });
            });
        }
        // ----------- PAYMENT BY COUNTRY END ----------------

        // ----------- PAYMENT BY CART PRICE START ----------------
        // Remove those payments which minimum is above cartPrice and maximum is under cartPrice
        _remove(possiblePayments, function(n) {return n.configuration.minimum ? (cartPrice <= n.configuration.minimum) : false;});
        _remove(possiblePayments, function(n) {return n.configuration.maximum ? (cartPrice > n.configuration.maximum) : false;});
        // ----------- PAYMENT BY CART PRICE END ----------------

        return possiblePayments;
    };

    this.getShippingCountries = function() {
        let deferred = $q.defer();

        $http.get("/api/v1/eshop/countries", null, {cache: true})
            .then(function(data) {
                deferred.resolve(data.data);
            })
            .catch(function() {
                deferred.reject();
            });

        return deferred.promise;
    };

}]);
