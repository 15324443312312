import * as tracker from '../utils/commerce';
import $ from 'jquery';
import _some from 'lodash/some';
import _find from 'lodash/find';

window.app.controller('CartCtrl',
    ['$scope', 'PageMetaServ',
        function($scope, meta) {

            meta({
                title: 'Košík | Cristine stylové květinářství',
                description: '',
                robots: 'noindex',
            });

            tracker.beginCheckout($scope.cart);

        }
        ]);
