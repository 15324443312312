import loadingEntity from '../../views/partials/loading-entity.html';

window.app.directive('anyLoader', function() {

    var controller = ['$scope', function($scope) {
    }];

    var link = function(scope, element, attrs) {
    };

    return {
        templateUrl: loadingEntity,
        link: link,
        scope: {
            "message": "@"
        },
        controller: controller,
    };
});
