import * as tracker from '../utils/commerce';
import $ from 'jquery';
import _some from 'lodash/some';
import _find from 'lodash/find';

window.app.controller('OrderCustomerCtrl',
    ['$scope', 'PageMetaServ',
        function($scope, meta) {

            meta({
                title: 'Dodací údaje | Cristine stylové květinářství',
                description: '',
                robots: 'noindex',
            });


            tracker.continueCheckout($scope.cart, 3);
        }
        ]);
