export default {
    ga:                 'UA-87084815-1',
    adwords:            'AW-867254014',
    adwordsConvId:      'OKVFCKydoewBEP79xJ0D',
    heureka:            '2fc796c9a037857614f40d5886916c39',
    // gtm:    '',
    // smartformId:      'fRCR7YgGXp',
    seznamRetargeting:   47500,
    seznamConvId:       100033641,
    // fbAppId:            305476086278632,
    fbPixel:            "982191795222993",
    // mailkitApiId:       69607578,
    // eHub:           "4b6cde64",
    // yandex:             45314013,

    // GLOBAL TECH SETTING
    logLevel: 'debug',
    langs: '',
    host: 'http://localhost:8000',
    apiUrl: 'http://localhost:8000/api/v1',
    authorizeUrl: '/authorize',
}
